import React from 'react';
import KeepAlive from 'react-activation';
import UserForm from '@modules/basicInfo/userInfo/userForm';
import { useLocation } from 'react-router-dom';

function UserEdit() {
  const location = useLocation();
  return (
    <KeepAlive id={location.pathname} cacheKey="编辑用户" name={location.pathname}>
      <div style={{ height: 750 }}>
        <UserForm />
      </div>
    </KeepAlive>
  );
}
export default UserEdit;
