import {
  DatePicker, InputNumber, Select, Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import random from '@utils/random';
import _ from 'lodash';
import moment from 'moment';
import DecisionResult from '@modules/supplier/order/decision/decisionResult';
import { inputType } from '@/components';

const genRow = (theOther, param, child, data) => {
  let newChild = _.cloneDeep(child);
  if (newChild.type === 'next') {
    const copyInitData = newChild.data.initData.map((i) => ({ ...i, key: random() }));
    newChild = { type: newChild.type, data: { options: newChild.data.options, initData: copyInitData } };
  } else if (data) {
    newChild = { ...newChild, data };
  }
  return {
    key: random(),
    type: theOther.type,
    param,
    value: theOther.value,
    child: newChild,
  };
};

const findTheOther = (opt, flag) => {
  const filterOpt = opt.filter((i) => i.flag + flag === 0);
  return { value: filterOpt[0].value, type: filterOpt[0].type };
};

const findAlreadyExist = (data, value) => {
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].value === value) {
      return i;
    }
  }
  return -1;
};

const filter = (data, value) => (
  data.filter((item) => item.value === value)
);

const rowSpan = (index) => {
  if (index === 0) {
    return 2;
  }
  return 0;
};

const DecisionSelect = ({
  sourceData,
  upSourceData,
  dePaMap,
  root,
  autoGen,
}) => {
  const { initData, options } = sourceData;
  const [data, setData] = useState(initData);
  const paramType = dePaMap[data[0].type]?.inTy;
  useEffect(() => {
    upSourceData({ initData: data, options });
  }, [data]);
  const valueColumn = {
    key: 'value',
    dataIndex: 'value',
    render: (text, item, index) => (
      <div>
        <Select
          value={item.value}
          style={{ width: options[0].width }}
          options={options}
          onChange={(value, select) => {
            if (value === 'none') {
              setData((preData) => {
                const copyData = _.cloneDeep(preData);
                copyData[index].type = select.type;
                copyData[index].value = value;
                copyData[index].param = null;
                return filter(copyData, value);
              });
            } else {
              const alreadyExistsIndex = findAlreadyExist(data, value);
              if (alreadyExistsIndex !== -1) {
                setData((preData) => {
                  const theOther = findTheOther(options, select.flag);
                  const copyData = _.cloneDeep(preData);
                  copyData[alreadyExistsIndex].value = theOther.value;
                  copyData[alreadyExistsIndex].type = theOther.type;
                  copyData[index].value = value;
                  copyData[index].type = select.type;
                  return copyData;
                });
              } else {
                setData(((preData) => {
                  const copyData = _.cloneDeep(preData);
                  copyData[index].value = value;
                  copyData[index].type = select.type;
                  if (!copyData[index].param || preData[index].type !== select.type) {
                    copyData[index].param = dePaMap[select.type]?.dePa;
                  }
                  if (!autoGen) {
                    return [...filter(copyData, value)];
                  }
                  const theOther = findTheOther(options, select.flag);
                  return (index === 0
                    ? [...filter(copyData, value), genRow(theOther, copyData[0].param, copyData[0].child, preData[1]?.child?.data)]
                    : [genRow(theOther, copyData[1].param, copyData[1].child, preData[0].child.data), ...filter(copyData, value)]);
                }));
              }
            }
          }}
        />
      </div>
    ),
  };
  const paramColumn = {
    key: 'param',
    dataIndex: 'param',
    render: (text, item, index) => {
      switch (paramType) {
        case inputType.datePicker: {
          return {
            children: (
              <div>
                <DatePicker
                  allowClear={false}
                  style={{ width: 120 }}
                  value={moment(item.param)}
                  addonAfter={dePaMap[item.type]?.after}
                  onChange={(date, dateStr) => {
                    setData((preData) => {
                      const copyData = _.cloneDeep(preData);
                      for (let i = 0; i < copyData.length; i += 1) {
                        copyData[i].param = dateStr;
                      }
                      return copyData;
                    });
                  }}
                />
              </div>
            ),
            props: { rowSpan: rowSpan(index) },
          };
        }
        case inputType.inputNumber: {
          return {
            children: (
              <div>
                <InputNumber
                  min={dePaMap[item.type]?.min}
                  controls={false}
                  style={{ width: 100 }}
                  value={item.param}
                  addonAfter={dePaMap[item.type]?.after}
                  onChange={(value) => {
                    setData((preData) => {
                      const copyData = _.cloneDeep(preData);
                      for (let i = 0; i < copyData.length; i += 1) {
                        copyData[i].param = value;
                      }
                      return copyData;
                    });
                  }}
                />
              </div>
            ),
            props: { rowSpan: rowSpan(index) },
          };
        }
        default: return {};
      }
    },
  };
  const childColumns = {
    key: 'child',
    dataIndex: 'child',
    render: (text, { child }, index) => {
      if (child.type === 'next') {
        return (
          <>
            <DecisionSelect
              sourceData={child.data}
              upSourceData={(childData) => {
                setData((preData) => {
                  const copyData = _.cloneDeep(preData);
                  copyData[index].child.data = childData;
                  return copyData;
                });
              }}
              dePaMap={dePaMap}
              autoGen
            />
            <div />
          </>
        );
      }
      return (
        <DecisionResult
          type={child.type}
          sourceData={child.data}
          upSourceData={(childData) => {
            setData((preData) => {
              const copyData = _.cloneDeep(preData);
              copyData[index].child.data = childData;
              return copyData;
            });
          }}
        />
      );
    },
  };
  const columns = paramType === inputType.datePicker ? [paramColumn, valueColumn, childColumns] : [valueColumn, paramColumn, childColumns];

  return (
    <Table columns={columns} dataSource={data} pagination={false} showHeader={false} bordered={false} size="small" />
  );
};

DecisionSelect.propTypes = {
  sourceData: PropTypes.shape({
    initData: PropTypes.arrayOf(PropTypes.shape({})),
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  upSourceData: PropTypes.func,
  dePaMap: PropTypes.shape({}),
  root: PropTypes.bool,
  autoGen: PropTypes.bool,
};

DecisionSelect.defaultProps = {
  sourceData: {
    options: [{}],
    initData: [{}],
  },
  upSourceData: () => {},
  dePaMap: {},
  root: false,
  autoGen: false,
};

export default DecisionSelect;
