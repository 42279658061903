import styled from 'styled-components';

const Content = styled.div`
  margin-bottom: 12px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
`;
const ComponetsContent = styled.div`
  padding: 0px 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
`;
const BodyHtml = styled.div`
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
`;
// const DatePick = styled.DatePicker`
//   .ant-picker-year-btn{
//     display: none;
//   }
// `;
export default {
  Content,
  BodyHtml,
  ComponetsContent,
  // DatePick,
};
