import { idNoFormValidator, phoneFormValidator } from '@utils/validate';
import { inputType } from '@/components';
import { datetimeFormat, options } from '@/constants';
import { options as optionHooks } from '@/hooks';

export const getAccountFields = ({ isEdit }) => [
  {
    name: 'loginAccount',
    label: '登陆账号',
    type: inputType.input,
    inputProps: {
      placeholder: '请输入',
    },
    rules: [{
      required: true,
      allowClear: true,
      validator: phoneFormValidator,
    }],
  }, {
    name: 'loginPassword',
    label: '登陆密码',
    type: inputType.input_password,
    inputProps: {
      placeholder: '请输入',
      allowClear: true,
      autoComplete: 'new-password',
    },
    rules: [{
      required: isEdit,
    }],
  }, {
    name: 'roleIds',
    label: '角色',
    type: inputType.select,
    inputProps: {
      placeholder: '请选择',
      allowClear: true,
      showSearch: true,
      options: optionHooks.useRoles(),
      mode: 'multiple',
      optionLabelProp: 'label',
    },
    rules: [{
      required: true,
    }],
  }];
export const getBasicFormFields = ({ isAddPage, departments }) => [
  {
    name: 'userName',
    label: '姓名',
    inputProps: {
      placeholder: '请输入姓名',
    },
    rules: [{
      required: true,
      allowClear: true,
    }],
  }, {
    name: 'idNo',
    label: '身份证',
    inputProps: {
      placeholder: '请输入身份证',
      allowClear: true,
    },
    rules: [{
      required: true,
      validator: idNoFormValidator,
    }],
  }, {
    name: 'mobile',
    label: '工作手机号',
    inputProps: {
      placeholder: '请输入工作手机号',
      allowClear: true,
    },
    rules: [{
      required: true,
      validator: phoneFormValidator,
    }],
  }, {
    name: 'weChatNo',
    label: '工作微信号',
    inputProps: {
      placeholder: '请输入工作微信号',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'personalMobile',
    label: '私人手机号',
    inputProps: {
      placeholder: '请输入私人手机号',
      allowClear: true,
    },
    // rules: [{
    //   required: true,
    //   validator: phoneFormValidator,
    // }],
  }, {
    name: 'position',
    label: '职位',
    inputProps: {
      placeholder: '请输入职位',
    },
  },
  // {
  //   name: 'loginAccount',
  //   label: '登录账号',
  //   inputProps: {
  //     placeholder: '请输入登录账号',
  //   },
  //   rules: [{
  //     required: true,
  //   }],
  // }, {
  //   name: 'loginPassword',
  //   label: '登录密码',
  //   type: inputType.input_password,
  //   inputProps: {
  //     placeholder: '请输入登录密码',
  //   },
  //   rules: [{
  //     required: isAddPage, // 新增才必填
  //   },
  //   {
  //     pattern: /^[^\s]*$/,
  //     message: '禁止输入空格',
  //   }],
  // },
  {
    name: 'bankName',
    label: '银行名称',
    inputProps: {
      placeholder: '请输入银行名称',
    },
  }, {
    name: 'bankAccount',
    label: '银行账号',
    inputProps: {
      placeholder: '请输入银行账号',
    },
  }, {
    name: 'departmentId',
    label: '部门',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请选择部门',
      options: departments,
      showSearch: true,
      optionFilterProp: 'label',
    },
  }];

// 部门后的换行基本信息
export const getBaseFormFieldsBr = () => [
  // {
  //   name: 'disable',
  //   label: '是否禁用',
  //   type: inputType.radioGroup,
  //   inputProps: {
  //     options: options.isDisable,
  //   },
  //   rules: [{
  //     required: true,
  //   }],
  // },
  {
    name: 'status',
    label: '在离职状态',
    type: inputType.radioGroup,
    inputProps: {
      options: options.userStatus,
    },
    rules: [{
      required: true,
    }],
  }, {
    kye: 'entryDate',
    name: 'entryDate',
    label: '入职日期',
    type: inputType.datePicker,
    inputProps: {
      placeholder: '请选择入职日期',
      format: datetimeFormat.date,
    },
    rules: [{
      required: true,
    }],
  }, {
    kye: 'resignDate',
    name: 'resignDate',
    label: '离职日期',
    type: inputType.datePicker,
    inputProps: {
      placeholder: '请选择离职日期',
      format: datetimeFormat.date,
    },
  }];

export const getAuthorizeFormFields = (
  {
    setControlCompanyData,
    targetKeysByControlCompany,
    setAuthorizeCompanyData,
    targetKeysByAuthorizeCompany,
    setAuthorizeStoreData,
    targetKeysByAuthorizeStore,
  },
) => [
  {
    name: 'controlCompanyIds',
    label: '负责工厂',
    requestUrl: '/common/companies/forSelect',
    setData: setControlCompanyData,
    targetKeysList: targetKeysByControlCompany,
  }, {
    name: 'authorizeCompanyIds',
    label: '授权工厂',
    requestUrl: '/common/companies/forSelect',
    setData: setAuthorizeCompanyData,
    targetKeysList: targetKeysByAuthorizeCompany,
  }, {
    name: 'authorizeStoreIds',
    label: '授权门店',
    requestUrl: '/common/store/forSelect',
    setData: setAuthorizeStoreData,
    targetKeysList: targetKeysByAuthorizeStore,
  }];

export const getBelongStoreFields = (storeData, groupData) => [
  {
    name: 'belongStoreId',
    label: '所属门店',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请输入或选择门店',
      allowClear: true,
      options: storeData,
      showSearch: true,
      optionFilterProp: 'label',
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'belongGroupId',
    label: '所属小组',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请先选择门店',
      allowClear: true,
      options: groupData,
      showSearch: true,
      optionFilterProp: 'label',
    },
    rules: [{
      required: true,
    }],
  }];

export const getRoleFormFields = ({
  admin, setAdmin,
  resident, setResident,
  residentManager, setResidentManager,
  finance, setFinance, roleData, roomManager, setRoomManager,
}) => [
  // {
  //   name: 'admin',
  //   label: '是否管理员',
  //   type: inputType.switch,
  //   inputProps: {
  //     checked: admin,
  //     onChange: setAdmin,
  //   },
  // },
  {
    name: 'resident',
    label: '是否驻厂',
    type: inputType.switch,
    inputProps: {
      checked: resident,
      onChange: setResident,
    },
  }, {
    name: 'residentManager',
    label: '是否驻厂主管',
    type: inputType.switch,
    inputProps: {
      checked: residentManager,
      onChange: setResidentManager,
    },
  }, {
    name: 'finance',
    label: '是否财务',
    type: inputType.switch,
    inputProps: {
      checked: finance,
      onChange: setFinance,
    },
  }, {
    name: 'roomManager',
    label: '是否宿舍主管',
    type: inputType.switch,
    inputProps: {
      checked: roomManager,
      onChange: setRoomManager,
    },
  }, {
    name: 'roleIds',
    label: '角色分配',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请输入或选择角色',
      allowClear: true,
      options: roleData,
      mode: 'multiple',
      showSearch: true,
      optionFilterProp: 'label',
    },
  }];

export default {};
