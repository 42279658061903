import random from '@utils/random';

// 这些options实际上只有会员工价/供应商费用用了，内部提成和外部奖励写在自己的js上。因为只有会员工价有正反，其余的只是简单的list
const noneSelect = [{
  value: 'none', label: '无', type: 'none', flag: 0, width: 80,
}];
const noneGenderSelect = [{
  value: 'none', label: '男/女', type: 'none', flag: 0, width: 80,
}];
const genderSelect = [{
  value: 'man', label: '男', type: 'gender', flag: 1, width: 80,
}, {
  value: 'woman', label: '女', type: 'gender', flag: -1, width: 80,
}];
const nonePunchSelect = [{
  value: 'none', label: '纯', type: 'none', flag: 0, width: 125,
}];
const punchDaySelect = [{
  value: 'punch_day_ok', label: '打卡满(天)', type: 'punch_day', flag: 2, width: 125,
}, {
  value: 'punch_day_no_ok', label: '打卡不满(天)', type: 'punch_day', flag: -2, width: 125,
}];
const punchHourSelect = [{
  value: 'punch_hour_ok', label: '打卡满(时)', type: 'punch_hour', flag: 3, width: 125,
}, {
  value: 'punch_hour_no_ok', label: '打卡不满(时)', type: 'punch_hour', flag: -3, width: 125,
}];
const workDaySelect = [{
  value: 'work_day_ok', label: '在职满(天)', type: 'work_day', flag: 4, width: 125,
}, {
  value: 'work_day_no_ok', label: '在职不满(天)', type: 'work_day', flag: -4, width: 125,
}];
const workHourSelect = [{
  value: 'work_hour_ok', label: '在职满(时)', type: 'work_hour', flag: 5, width: 125,
}, {
  value: 'work_hour_no_ok', label: '在职不满(时)', type: 'work_hour', flag: -5, width: 125,
}];
const jobSelect = [{
  value: 'job_ok', label: '在职', type: 'job', flag: 6, width: 90,
}, {
  value: 'job_no_ok', label: '不在职', type: 'job', flag: -6, width: 90,
}];

const htmlConditions = [
  {
    name: 'man',
    type: 'gender',
    var: 'var-gender',
    operator: 'EQUAL',
    param: 'male',
  }, {
    name: 'woman',
    type: 'gender',
    var: 'var-gender',
    operator: 'EQUAL',
    param: 'female',
  }, {
    name: 'punch_hour_ok',
    type: 'punch_hour',
    var: 'var-punch-hour',
    operator: 'GTE',
  }, {
    name: 'punch_hour_no_ok',
    type: 'punch_hour',
    var: 'var-punch-hour',
    operator: 'LT',
  }, {
    name: 'punch_day_ok',
    type: 'punch_day',
    var: 'var-punch-day',
    operator: 'GTE',
  }, {
    name: 'punch_day_no_ok',
    type: 'punch_day',
    var: 'var-punch-day',
    operator: 'LT',
  }, {
    name: 'work_hour_ok',
    type: 'work_hour',
    var: 'var-work-hour',
    operator: 'GTE',
  }, {
    name: 'work_hour_no_ok',
    type: 'work_hour',
    var: 'var-work-hour',
    operator: 'LT',
  }, {
    name: 'work_day_ok',
    type: 'work_day',
    var: 'var-work-day',
    operator: 'GTE',
  }, {
    name: 'work_day_no_ok',
    type: 'work_day',
    var: 'var-work-day',
    operator: 'LT',
  }, {
    name: 'job_ok',
    type: 'job',
    operator: 'DATE_CONTAINS',
    var: 'var-onJob',
  }, {
    name: 'job_no_ok',
    type: 'job',
    operator: 'DATE_NOT_CONTAINS',
    var: 'var-onJob',
  }, {
    name: 'none',
    type: 'none',
    operator: null,
    var: 'var-pass',
  },
];

const filterConditionByVar = (varCode, operator, param) => {
  const filter = htmlConditions.filter(
    (i) => i.var === varCode && i.operator === operator && ((i.param && i.param === param) || !i.param),
  );
  return filter[0];
};

const filterConditionByName = (name) => {
  const filter = htmlConditions.filter((i) => i.name === name);
  return filter[0];
};

const childOptions = (param) => {
  if (param === 'punch') {
    return [...punchDaySelect, ...punchHourSelect, ...workDaySelect, ...workHourSelect, ...jobSelect, ...nonePunchSelect];
  }
  if (param === 'date') {
    return [...punchDaySelect, ...punchHourSelect, ...workDaySelect, ...workHourSelect, ...jobSelect, ...noneSelect];
  }
  return [];
};

const profitChildOptions = (param) => {
  if (param === 'punch') {
    return [...punchDaySelect, ...punchHourSelect, ...workDaySelect, ...workHourSelect, ...jobSelect, ...nonePunchSelect];
  }
  if (param === 'date') {
    return [...punchDaySelect, ...punchHourSelect, ...workDaySelect, ...workHourSelect, ...jobSelect, ...noneSelect];
  }
  return [];
};

const api2Profit = (apiData) => {
  const trans = (nodeType, node, punchOrDate) => {
    if (nodeType === 'root') {
      const initData = [];
      node.nextNodes.forEach((i) => {
        const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
        const { type } = i;
        initData.push({
          key: random(),
          type: h5Condition.type,
          value: h5Condition.name,
          param: h5Condition.param || i.condition.constantValue,
          child: {
            type,
            data: trans(type, type === 'next' ? i.nextNodes : i.result, punchOrDate),
          },
        });
      });
      return {
        options: [...genderSelect, ...noneGenderSelect],
        initData,
      };
    }
    if (nodeType === 'next') {
      const initData = [];
      node.forEach((i) => {
        const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
        const { type } = i;
        initData.push({
          key: random(),
          type: h5Condition.type,
          value: h5Condition.name,
          param: h5Condition.param || i.condition.constantValue,
          child: {
            type,
            data: trans(type, type === 'next' ? i.nextNodes : i.result, punchOrDate),
          },
        });
      });
      return {
        options: profitChildOptions(punchOrDate),
        initData,
      };
    }
    return {
      settlementType: punchOrDate,
      settlementSelect: node.type,
      num: node.amount,
    };
  };

  const result = [];
  apiData?.forEach((i) => {
    result.push({
      key: random(),
      settleModel: i.type,
      dateStart: i.startDate,
      dateEnd: i.endDate,
      punchDecision: i.punch ? trans('root', i.punch, 'punch') : null,
      dateDecision: i.date ? trans('root', i.date, 'date') : null,
    });
  });
  return result;
};

const profit2Api = (settlement) => {
  const trans = (nodeType, nodeData) => {
    if (nodeType === 'root') {
      const next = [];
      nodeData.initData.forEach((i) => {
        const h5Condition = filterConditionByName(i.value);
        const iType = i.child.type;
        if (iType === 'next') {
          next.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            nextNodes: trans('next', i.child.data),
          });
        } else {
          next.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            result: trans('result', i.child.data),
          });
        }
      });
      return {
        type: 'root',
        nextNodes: next,
      };
    }
    if (nodeType === 'next') {
      const result = [];
      nodeData.initData.forEach((i) => {
        const h5Condition = filterConditionByName(i.value);
        const iType = i.child.type;
        if (iType === 'next') {
          result.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            nextNodes: trans('next', i.child.data),
          });
        } else {
          result.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            result: trans('result', i.child.data),
          });
        }
      });
      return result;
    }
    if (nodeType === 'result') {
      return {
        type: nodeData.settlementSelect,
        amount: nodeData.num,
      };
    }
    return {};
  };

  const result = [];
  settlement.forEach((i) => {
    result.push({
      startDate: i.dateStart,
      endDate: i.dateEnd,
      type: i.settleModel,
      punch: i.punchDecision ? trans('root', i.punchDecision) : null,
      date: i.dateDecision ? trans('root', i.dateDecision) : null,
    });
  });
  return result;
};

const api2Settlement = (apiData) => {
  const trans = (nodeType, node, punchOrDate) => {
    if (nodeType === 'root') {
      const initData = [];
      node.nextNodes.forEach((i) => {
        const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
        const { type } = i;
        initData.push({
          key: random(),
          type: h5Condition.type,
          value: h5Condition.name,
          param: h5Condition.param || i.condition.constantValue,
          child: {
            type,
            data: trans(type, type === 'next' ? i.nextNodes : i.result, punchOrDate),
          },
        });
      });
      return {
        options: [...genderSelect, ...noneGenderSelect],
        initData,
      };
    }
    if (nodeType === 'next') {
      const initData = [];
      node.forEach((i) => {
        const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
        const { type } = i;
        initData.push({
          key: random(),
          type: h5Condition.type,
          value: h5Condition.name,
          param: h5Condition.param || i.condition.constantValue,
          child: {
            type,
            data: trans(type, type === 'next' ? i.nextNodes : i.result, punchOrDate),
          },
        });
      });
      return {
        options: childOptions(punchOrDate),
        initData,
      };
    }
    return {
      settlementType: punchOrDate,
      settlementSelect: node.type,
      num: node.amount,
    };
  };

  const result = [];
  apiData?.forEach((i) => {
    result.push({
      key: random(),
      settleModel: i.type,
      dateStart: i.startDate,
      dateEnd: i.endDate,
      punchDecision: i.punch ? trans('root', i.punch, 'punch') : null,
      dateDecision: i.date ? trans('root', i.date, 'date') : null,
    });
  });
  return result;
};

const settlement2Api = (settlement) => {
  const trans = (nodeType, nodeData) => {
    if (nodeType === 'root') {
      const next = [];
      nodeData.initData.forEach((i) => {
        const h5Condition = filterConditionByName(i.value);
        const iType = i.child.type;
        if (iType === 'next') {
          next.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            nextNodes: trans('next', i.child.data),
          });
        } else {
          next.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            result: trans('result', i.child.data),
          });
        }
      });
      return {
        type: 'root',
        nextNodes: next,
      };
    }
    if (nodeType === 'next') {
      const result = [];
      nodeData.initData.forEach((i) => {
        const h5Condition = filterConditionByName(i.value);
        const iType = i.child.type;
        if (iType === 'next') {
          result.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            nextNodes: trans('next', i.child.data),
          });
        } else {
          result.push({
            type: iType,
            condition: {
              varComputerCode: h5Condition.var,
              operator: h5Condition.operator,
              constantValue: h5Condition.param || i.param,
            },
            result: trans('result', i.child.data),
          });
        }
      });
      return result;
    }
    if (nodeType === 'result') {
      return {
        type: nodeData.settlementSelect,
        amount: nodeData.num,
      };
    }
    return {};
  };

  const result = [];
  settlement.forEach((i) => {
    result.push({
      startDate: i.dateStart,
      endDate: i.dateEnd,
      type: i.settleModel,
      punch: i.punchDecision ? trans('root', i.punchDecision) : null,
      date: i.dateDecision ? trans('root', i.dateDecision) : null,
    });
  });
  return result;
};

const api2FissionReward = (apiData, condition) => {
  const result = [];
  apiData?.forEach((i) => {
    const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
    result.push({
      key: random(),
      dateStart: i.startDate,
      dateEnd: i.endDate,
      decision: {
        options: [...condition],
        initData: [
          {
            key: random(),
            type: h5Condition.type,
            value: h5Condition.name,
            param: h5Condition.param || i.condition.constantValue,
            child: {
              type: 'fissionRewardResult',
              data: {
                num: {
                  recommender: i.result.recommender, // 推荐人
                  referee: i.result.referee, // 被推荐人
                  recruiter: i.result.recruiter,
                  groupLeader: i.result.groupLeader,
                  storeLeader: i.result.storeLeader,
                },
              },
            },
          },
        ],
      },
    });
  });
  return result;
};

const fissionReward2Api = (fissionReward) => {
  const result = [];
  fissionReward?.forEach((i) => {
    const h5Condition = filterConditionByName(i.decision.initData[0].value);
    result.push({
      startDate: i.dateStart,
      endDate: i.dateEnd,
      condition: {
        varComputerCode: h5Condition.var,
        operator: h5Condition.operator,
        constantValue: i.decision.initData[0].param,
      },
      result: {
        recommender: i.decision.initData[0].child.data.num.recommender, // 推荐人
        referee: i.decision.initData[0].child.data.num.referee, // 被推荐人
        recruiter: i.decision.initData[0].child.data.num.recruiter,
        groupLeader: i.decision.initData[0].child.data.num.groupLeader,
        storeLeader: i.decision.initData[0].child.data.num.storeLeader,
      },
    });
  });
  return result;
};

const api2EmpReward = (model, apiData, stubCondition, longLineCondition) => {
  const result = [];
  apiData?.forEach((i) => {
    const h5Condition = filterConditionByVar(i.condition.varComputerCode, i.condition.operator, i.condition.constantValue);
    result.push({
      key: random(),
      decision: {
        options: model === 'STUB' ? [...stubCondition] : [...longLineCondition],
        initData: [
          {
            key: random(),
            type: h5Condition.type,
            value: h5Condition.name,
            param: h5Condition.param || i.condition.constantValue,
            child: {
              type: 'empRewardResult',
              data: {
                empRewardModel: model,
                num: {
                  recruiter: i.result.recruiter,
                  groupLeader: i.result.groupLeader,
                  storeLeader: i.result.storeLeader,
                },
              },
            },
          },
        ],
      },
      storeIds: i.storeIds,
      dateStart: i.startDate,
      dateEnd: i.endDate,
    });
  });
  return result;
};

const empReward2Api = (empReward) => {
  const result = [];
  empReward?.forEach((i) => {
    const h5Condition = filterConditionByName(i.decision.initData[0].value);
    result.push({
      startDate: i.dateStart,
      endDate: i.dateEnd,
      storeIds: i.storeIds,
      condition: {
        varComputerCode: h5Condition.var,
        operator: h5Condition.operator,
        constantValue: i.decision.initData[0].param,
      },
      result: {
        recruiter: i.decision.initData[0].child.data.num.recruiter,
        groupLeader: i.decision.initData[0].child.data.num.groupLeader,
        storeLeader: i.decision.initData[0].child.data.num.storeLeader,
      },
    });
  });
  return result;
};

export default {
  noneSelect,
  noneGenderSelect,
  nonePunchSelect,
  genderSelect,
  punchDaySelect,
  punchHourSelect,
  workDaySelect,
  workHourSelect,
  jobSelect,
  api2EmpReward,
  empReward2Api,
  api2FissionReward,
  fissionReward2Api,
  api2Settlement,
  settlement2Api,
  api2Profit,
  profit2Api,
};
