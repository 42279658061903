import React, { lazy, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const BuildingManage = lazy(() => import('@/modules/dormitoryManage/InformationSettings/buildingManage'));
const FloorManage = lazy(() => import('@/modules/dormitoryManage/InformationSettings/floorManage'));
const RoomManage = lazy(() => import('@/modules/dormitoryManage/InformationSettings/roomManage'));
const BedManage = lazy(() => import('@/modules/dormitoryManage/InformationSettings/bedManage'));
const BedData = lazy(() => import('@modules/dormitoryManage/bedDataMange/bedData'));
const BedReleasePlan = lazy(() => import('@modules/dormitoryManage/bedDataMange/bedReleasePlan'));
const EmptyBedManage = lazy(() => import('@modules/dormitoryManage/bedDataMange/emptyBedManage'));
const StayManage = lazy(() => import('@modules/dormitoryManage/dailyManage/stayManage'));
const AssetManage = lazy(() => import('@modules/dormitoryManage/dailyManage/assetManage'));
const DisciplineManage = lazy(() => import('@modules/dormitoryManage/dailyManage/disciplineManage'));
const CheckManage = lazy(() => import('@modules/dormitoryManage/dailyManage/checkManage'));
const RepairManage = lazy(() => import('@modules/dormitoryManage/dailyManage/repairManage'));
const AxpenseAccount = lazy(() => import('@modules/dormitoryManage/expenseManage/expenseAccount'));
const AxpenseImport = lazy(() => import('@modules/dormitoryManage/expenseManage/expenseImport'));
const ExpenseSettings = lazy(() => import('@modules/dormitoryManage/expenseManage/expenseSettings'));

export default {
  path: 'dormitoryManage',
  element: <Outlet />,
  options: {
    title: '宿舍管理',
    permissionKey: 'menu-dormitoryManage',
    key: 'menu-dormitoryManage',
    icon: <Icon.ContactsOutlined />,
    link: '/dormitoryManage',
  },
  children: [
    {
      path: 'InformationSettings',
      element: <Outlet />,
      options: {
        title: '信息设置',
        key: 'menu-dormitoryManage-InformationSettings',
        icon: null,
        link: '/dormitoryManage/InformationSettings',
      },
      children: [
        {
          path: 'buildingManage',
          element: <Outlet />,
          options: {
            title: '楼栋信息',
            key: 'menu-dormitoryManage-InformationSettings-buildingManage',
            icon: null,
            link: '/dormitoryManage/InformationSettings/buildingManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="楼栋信息" permissionKey="menu-dormitoryManage-InformationSettings-buildingManage" component={<BuildingManage />} />,
            },
          ],
        },
        {
          path: 'floorManage',
          element: <Outlet />,
          options: {
            title: '楼层信息',
            key: 'menu-dormitoryManage-InformationSettings-floorManage',
            icon: null,
            link: '/dormitoryManage/InformationSettings/floorManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="楼层信息" permissionKey="menu-dormitoryManage-InformationSettings-floorManage" component={<FloorManage />} />,
            },
          ],
        },
        {
          path: 'roomManage',
          element: <Outlet />,
          options: {
            title: '房间信息',
            key: 'menu-dormitoryManage-InformationSettings-roomManage',
            icon: null,
            link: '/dormitoryManage/InformationSettings/roomManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="房间信息" permissionKey="menu-dormitoryManage-InformationSettings-roomManage" component={<RoomManage />} />,
            },
          ],
        },
        {
          path: 'bedManage',
          element: <Outlet />,
          options: {
            title: '床位信息',
            key: 'menu-dormitoryManage-InformationSettings-bedManage',
            icon: null,
            link: '/dormitoryManage/InformationSettings/bedManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="床位信息" permissionKey="menu-dormitoryManage-InformationSettings-bedManage" component={<BedManage />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'bedDataManage',
      element: <Outlet />,
      options: {
        title: '床位数据管理',
        key: 'menu-dormitoryManage-bedDataManage',
        icon: null,
        link: '/dormitoryManage/bedDataManage',
      },
      children: [
        {
          path: 'bedData',
          element: <Outlet />,
          options: {
            title: '床位数据概述',
            key: 'menu-dormitoryManage-bedDataManage-bedData',
            icon: null,
            link: '/dormitoryManage/bedDataManage/bedData',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="床位数据概述" permissionKey="menu-dormitoryManage-bedDataManage-bedData" component={<BedData />} />,
            },
          ],
        },
        {
          path: 'bedReleasePlan',
          element: <Outlet />,
          options: {
            title: '床位释放计划',
            key: 'menu-dormitoryManage-bedDataManage-bedReleasePlan',
            icon: null,
            link: '/dormitoryManage/bedDataManage/bedReleasePlan',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="床位释放计划" permissionKey="menu-dormitoryManage-bedDataManage-bedReleasePlan" component={<BedReleasePlan />} />,
            },
          ],
        }, {
          path: 'emptyBedManage',
          element: <Outlet />,
          options: {
            title: '空床位查询',
            key: 'menu-dormitoryManage-bedDataManage-emptyBedManage',
            icon: null,
            link: '/dormitoryManage/bedDataManage/emptyBedManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="空床位查询" permissionKey="menu-dormitoryManage-bedDataManage-emptyBedManage" component={<EmptyBedManage />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'dailyManage',
      element: <Outlet />,
      options: {
        title: '日常管理',
        key: 'menu-dormitoryManage-dailyManage',
        icon: null,
        link: '/dormitoryManage/dailyManage',
      },
      children: [
        {
          path: 'stayManage',
          element: <Outlet />,
          options: {
            title: '在离宿管理',
            key: 'menu-dormitoryManage-dailyManage-liveManage',
            icon: null,
            link: '/dormitoryManage/dailyManage/stayManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="在离宿管理" permissionKey="menu-dormitoryManage-dailyManage-liveManage" component={<StayManage />} />,
            },
          ],
        },
        {
          path: 'assetManage',
          element: <Outlet />,
          options: {
            title: '资产管理',
            key: 'menu-dormitoryManage-dailyManage-assetManage',
            icon: null,
            link: '/dormitoryManage/dailyManage/assetManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="资产管理" permissionKey="menu-dormitoryManage-dailyManage-assetManage" component={<AssetManage />} />,
            },
          ],
        },
        {
          path: 'disciplineManage',
          element: <Outlet />,
          options: {
            title: '违纪管理',
            key: 'menu-dormitoryManage-dailyManage-disciplineManage',
            icon: null,
            link: '/dormitoryManage/dailyManage/disciplineManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="违纪管理" permissionKey="menu-dormitoryManage-dailyManage-disciplineManage" component={<DisciplineManage />} />,
            },
          ],
        },
        {
          path: 'checkManage',
          element: <Outlet />,
          options: {
            title: '点检管理',
            key: 'menu-dormitoryManage-dailyManage-checkManage',
            icon: null,
            link: '/dormitoryManage/dailyManage/checkManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="点检管理" permissionKey="menu-dormitoryManage-dailyManage-checkManage" component={<CheckManage />} />,
            },
          ],
        },
        {
          path: 'repairManage',
          element: <Outlet />,
          options: {
            title: '维修管理',
            key: 'menu-dormitoryManage-dailyManage-repairManage',
            icon: null,
            link: '/dormitoryManage/dailyManage/repairManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="维修管理" permissionKey="menu-dormitoryManage-dailyManage-repairManage" component={<RepairManage />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'expenseManage',
      element: <Outlet />,
      options: {
        title: '费用管理',
        key: 'menu-dormitoryManage-expenseManage',
        icon: null,
        link: '/dormitoryManage/expenseManage',
      },
      children: [
        {
          path: 'expenseSettings',
          element: <Outlet />,
          options: {
            title: '费用标准设置',
            key: 'menu-dormitoryManage-expenseManage-expenseSettings',
            icon: null,
            link: '/dormitoryManage/expenseManage/expenseSettings',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="费用标准设置" permissionKey="menu-dormitoryManage-expenseManage-expenseSettings" component={<ExpenseSettings />} />,
            },
          ],
        },
        {
          path: 'expenseImport',
          element: <Outlet />,
          options: {
            title: '费用导入',
            key: 'menu-dormitoryManage-expenseManage-expenseImport',
            icon: null,
            link: '/dormitoryManage/expenseManage/expenseImport',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="费用导入" permissionKey="menu-dormitoryManage-expenseManage-expenseImport" component={<AxpenseImport />} />,
            },
          ],
        },
        {
          path: 'expenseAccount',
          element: <Outlet />,
          options: {
            title: '费用核算',
            key: 'menu-dormitoryManage-expenseManage-expenseAccount',
            icon: null,
            link: '/dormitoryManage/expenseManage/expenseAccount',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="费用核算" permissionKey="menu-dormitoryManage-expenseManage-expenseAccount" component={<AxpenseAccount />} />,
            },
          ],
        },
      ],
    },
  ],
};
