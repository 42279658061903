import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import userInfo from './userInfo';

const Organization = lazy(() => import('./organization'));
const StoreManagement = lazy(() => import('./store/record'));
const BlackList = lazy(() => import('./black'));

export default {
  path: 'basicInfo',
  element: <Outlet />,
  options: {
    title: '基本信息',
    permissionKey: 'menu-baseInfo',
    key: 'menu-baseInfo',
    icon: <Icon.AppstoreOutlined />,
    link: '/basicInfo',
  },
  children: [
    {
      path: 'userInfo',
      element: <Outlet />,
      options: {
        title: '用户管理',
        permissionKey: 'menu-baseInfo-userManage',
        key: 'menu-baseInfo-userManage',
        icon: null,
        link: '/basicInfo/userInfo',
      },
      children: userInfo,
    },
    {
      path: 'organization',
      element: <Outlet />,
      options: {
        title: '组织架构',
        key: 'menu-baseInfo-organization',
        icon: null,
        link: '/basicInfo/organization',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-baseInfo-organization" title="组织架构" component={<Organization />} />,
        },
      ],
    },
    {
      path: 'storeManagement',
      element: <Outlet />,
      options: {
        title: '门店管理',
        key: 'menu-baseInfo-storeManage',
        icon: null,
        link: '/basicInfo/storeManagement',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-baseInfo-storeManage" title="门店管理" component={<StoreManagement />} />,
        },
      ],
    },
    {
      path: 'blackManagement',
      element: <Outlet />,
      allowAccess: true,
      options: {
        title: '白名单管理',
        key: 'menu-baseInfo-blackManage',
        icon: null,
        link: '/basicInfo/blackManagement',
        allowAccess: true,
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-baseInfo-blackManage" title="白名单管理" component={<BlackList />} />,
        },
      ],
    },
  ],
};
