import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const NewestList = lazy(() => import('@/modules/listManagement/newestStatus'));
const RegistraList = lazy(() => import('@/modules/listManagement/registrationList'));
const InterviewList = lazy(() => import('@/modules/listManagement/interviewList'));
const WaitingList = lazy(() => import('@/modules/listManagement/waitingList'));
const ResignationList = lazy(() => import('@/modules/listManagement/resignationList'));

export default {
  path: 'listManagement',
  element: <Outlet />,
  options: {
    title: '名单管理',
    permissionKey: 'menu-listManagement',
    key: 'listManagement',
    icon: <Icon.FileTextOutlined />,
    link: '/listManagement',
  },
  children: [
    {
      path: 'newestStatus',
      element: <Outlet />,
      options: {
        title: '最新状态',
        key: 'menu-listManagement-newestStatus',
        icon: null,
        link: '/listManagement/newestStatus',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="最新状态" permissionKey="menu-listManagement-newestStatus" component={<NewestList />} />,
        },
      ],
    },
    {
      path: 'registrationList',
      element: <Outlet />,
      options: {
        title: '报名名单',
        key: 'menu-listManagement-registrationList',
        icon: null,
        link: '/listManagement/registrationList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="报名名单" permissionKey="menu-listManagement-registrationList" component={<RegistraList />} />,
        },
      ],
    },
    {
      path: 'interviewList',
      element: <Outlet />,
      options: {
        title: '面试名单',
        key: 'menu-listManagement-interviewList',
        icon: null,
        link: '/listManagement/interviewList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="面试名单" permissionKey="menu-listManagement-interviewList" component={<InterviewList />} />,
        },
      ],
    },
    {
      path: 'waitingList',
      element: <Outlet />,
      options: {
        title: '待入职名单',
        key: 'menu-listManagement-waitingList',
        icon: null,
        link: '/listManagement/waitingList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="待入职名单" permissionKey="menu-listManagement-waitingList" component={<WaitingList />} />,
        },
      ],
    },
    {
      path: 'resignationList',
      element: <Outlet />,
      options: {
        title: '在离职名单',
        key: 'menu-listManagement-resignationList',
        icon: null,
        link: '/listManagement/resignationList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="在离职名单" permissionKey="menu-listManagement-resignationList" component={<ResignationList />} />,
        },
      ],
    },
  ],
};
