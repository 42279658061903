/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Row, Col, Space, DatePicker,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import FormItem from '../formItem';

const TopSearch = ({
  onSubmit,
  fields,
  extra,
  additional,
  initialValues,
  getForm,
  onExportData,
  ...rest
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (getForm) {
      getForm(form);
    }
  }, [getForm]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
      onValuesChange={onExportData}
    >
      <Row gutter={40}>
        {
          fields.map((f) => (f.fields?.length ? (
            <Col>
              <Form.Item name={f.name} label={f.label} style={{ marginBottom: 0 }}>
                <Row size={20} align="middle">
                  {
                    f.fields.map((cf, index) => (
                      <>
                        {index > 0 && <span style={{ margin: '0 10px 24px' }}>{f.separator}</span>}
                        <FormItem
                          mult={cf.mult}
                          form={form}
                          key={cf.name}
                          name={cf.name}
                          label={cf.label}
                          type={cf.type}
                          inputProps={{
                            ...cf.inputProps,
                            onChange: (val) => {
                              if (cf?.inputProps?.onChange) {
                                cf.inputProps.onChange(val, cf.name);
                              }
                            },
                          }}
                          labelAlign="right"
                        />
                      </>
                    ))
                  }
                </Row>
              </Form.Item>
            </Col>
          ) : (
            <div style={{ minWidth: 250, marginLeft: 30 }}>
              <FormItem mult={f.mult} form={form} name={f.name} label={f.label} type={f.type} inputProps={f.inputProps} labelAlign="right" />
            </div>
          )))
        }
        <Form.Item name="birthdayStart" label="会员生日">
          <DatePicker
            format="MM-DD"
            dropdownClassName="dateStyle"
          />
        </Form.Item>
        <span style={{ paddingLeft: 10, paddingRight: 10, marginTop: 5 }}>至</span>
        <Form.Item name="birthdayEnd">
          <DatePicker
            format="MM-DD"
            dropdownClassName="dateStyle"
          />
        </Form.Item>
        <Form.Item>
          <Space size={30} style={{ paddingLeft: 20 }}>
            <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>查询</Button>
            <Button
              onClick={() => {
                form.resetFields();
                form.submit();
              }}
            >
              重置
            </Button>
          </Space>
        </Form.Item>
        {extra}
      </Row>
    </Form>
  );
};

TopSearch.propTypes = {
  onSubmit: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      inputProps: PropTypes.shape({}),
    }),
  ),
  extra: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  additional: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  initialValues: PropTypes.shape({}),
  getForm: PropTypes.func,
  onExportData: PropTypes.func,
};

TopSearch.defaultProps = {
  onSubmit: () => { },
  fields: [],
  extra: null,
  additional: null,
  initialValues: {},
  getForm: () => { },
  onExportData: () => { },
};
export default TopSearch;
