import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const ComplaintList = lazy(() => import('@/modules/complaintManagement/complaintList'));
export default {
  path: 'complaintManagement',
  element: <Outlet />,
  options: {
    title: '投诉建议',
    permissionKey: 'menu-complaintManagement',
    key: 'menu-complaintManagement',
    icon: <Icon.ContactsOutlined />,
    link: '/complaintManagement',
  },
  children: [
    {
      path: 'list',
      element: <Outlet />,
      options: {
        title: '会员投诉',
        key: 'menu-complaintManagement-list',
        icon: null,
        link: '/complaintManagement/list',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="会员投诉" permissionKey="menu-complaintManagement-list" component={<ComplaintList />} />,
        },
      ],
    },
  ],
};
