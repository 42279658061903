/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Upload, Modal, Spin, message, Button,
} from 'antd';
import * as Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';

const action = () => process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOAD_URL;

const UploadFile = ({
  fileType, fileList, fileChange, beforeUpload, visible, multiple,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (fileList) {
      setFiles(fileList);
    }
  }, [fileList]);

  // 解析前端file的信息，给到后端
  const parseFiles = (targetFiles) => targetFiles.map((val) => {
    const newFileObj = {};
    newFileObj.fileKey = val.fileKey;
    newFileObj.url = val.url;
    newFileObj.md5 = val.md5;
    newFileObj.name = val.name;
    return newFileObj;
  });

  const checkPicture = (fileName) => /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);

  const handlePreview = async (file) => {
    if (file.name && checkPicture(file.name)) {
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else {
      window.open(file.url, '_blank');
    }
  };

  const handleCancel = () => setPreviewVisible(false);
  const onRemove = (file) => {
    const newFileList = [...(files.filter((val) => !(val.url === file.url)))];
    setFiles(newFileList);
    fileChange(parseFiles(newFileList));
  };

  // 上传文件之前的方法
  const beforeUploadFile = (file) => {
    const fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
    const fileNameLength = file.name.length;// 取到文件名长度
    const fileFormat = file.name.substring(fileName, fileNameLength);// 截
    if (fileFormat !== '.pdf') {
      message.error('上传文件必须为.pdf类型');
      return false;
    }
    return true;
  };

  // 自定义上传操作
  const customRequest = async ({ file }) => {
    if (!beforeUpload()) {
      return;
    }
    try {
      setLoading(true);
      let fileMd5 = '';
      await FileMD5(file).then((e) => {
        fileMd5 = e;
      }).catch((err) => {
        message.error('计算md5失败');
      });
      const isRepeat = files.some((val) => val.md5 === fileMd5);
      console.log('打印加密图片数字：', files, fileMd5, isRepeat);
      if (isRepeat) {
        message.warning('该文件已上传');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      await axios({
        method: 'post',
        url: action(),
        data: formData,
        timeout: 30000,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Device': 'pc',
          'X-User-Token': Cookies.get(storageKeys.token) || '',
        },
      }).then((res) => {
        if (res.data && res.data.code === 0) {
          const respData = res.data.data;
          const newFile = file;
          // newFile.uid = Math.round(Math.random() * -1000000000);
          newFile.fileKey = respData.fileKey;
          newFile.url = respData.url;
          newFile.md5 = fileMd5;
          const newFiles = [...files, newFile];
          setFiles(newFiles);
          fileChange(parseFiles(newFiles));
        } else {
          message.error('上传失败');
        }
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <Icon.PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  return (
    <>
      <Spin spinning={loading}>
        <Upload
          listType={fileType === '.pdf' ? '' : 'picture-card'}
          fileList={files}
          beforeUpload={fileType === '.pdf' ? beforeUploadFile : ''}
          onPreview={handlePreview}
          customRequest={customRequest}
          onRemove={onRemove}
          accept={fileType}
          multiple={multiple}
          showUploadList={fileType !== '.pdf'}
        >
          {fileType === '.pdf' ? <Button type="primary">上传合同</Button> : visible ? uploadButton : null}
        </Upload>
      </Spin>
      {
        fileType === '.pdf' ? '' : (
          <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} animation={false}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        )
      }
    </>
  );
};
UploadFile.propTypes = {
  fileType: PropTypes.string,
  fileList: PropTypes.instanceOf(Array),
  fileChange: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
  visible: PropTypes.bool,
  multiple: PropTypes.bool,
};

UploadFile.defaultProps = {
  fileList: [],
  fileType: 'image/*', // image/*
  beforeUpload: () => true,
  visible: true,
  multiple: false,
};

export default UploadFile;
