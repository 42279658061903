import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';

import authStore from '@stores/auth';
import documentStore from '@stores/document';

const ProtectedRoute = ({
  component,
  permissionKey,
  allowAccess,
  title,
}) => {
  const location = useLocation();
  useEffect(() => {
    document.title = `${process.env.REACT_APP_WEBSITE_TITLE}${title ? ` - ${title}` : ''}`;
    // document.title = authStore.profile.username === '任晓洋' ? `惠州华众人力${title ? ` - ${title}` : ''}` : `众鼎人力${title ? ` - ${title}` : ''}`;
    documentStore.setPageTitle(title);
  }, [title, authStore?.profile?.username]);

  if (!authStore.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const isAuthorized = authStore.isAdmin || allowAccess || authStore.permissions?.includes(permissionKey);
  if (!isAuthorized) {
    return <Navigate to="/not-authorized" state={{ from: location }} />;
  }

  return component;
};

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  permissionKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  allowAccess: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  title: '',
  allowAccess: false,
};

export default observer(ProtectedRoute);
