import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import SupplierProfitForm from '@modules/supplier/order/orderModify/supplierPorfitForm';

const CopySupplier = ({
  key, num, sourceOrderId, onFinish,
}) => {
  const html = [];
  const [unFinish, setUnFinish] = useState(num);
  useEffect(() => {
    if (unFinish === 0) {
      onFinish();
    }
  }, [unFinish]);
  for (let i = 0; i < num; i += 1) {
    html.push(
      <div style={{ marginBottom: 50 }}>
        <SupplierProfitForm
          sourceOrderId={sourceOrderId}
          index={i + 1}
          onAfterSubmit={() => { setUnFinish((pre) => pre - 1); }}
        />
      </div>,
    );
  }
  return html;
};

CopySupplier.propTypes = {
  key: PropTypes.string,
  num: PropTypes.number,
  sourceOrderId: PropTypes.string,
  onFinish: PropTypes.func,
};

CopySupplier.defaultProps = {
  key: '',
  num: 0,
  sourceOrderId: '',
  onFinish: () => {},
};

export default CopySupplier;
