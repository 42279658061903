import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import routes from './routes';
import './App.less';

moment.locale('zh-cn');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const MyRouter = () => {
  const element = useRoutes(routes);
  return element;
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider locale={locale}>
      <Router basename={process.env.REACT_APP_ROUTER_BASENAME || '/'}>
        <Suspense fallback={<div>Loading...</div>}>
          <MyRouter />
        </Suspense>
      </Router>
    </ConfigProvider>
  </QueryClientProvider>
);

export default App;
