import React, { useState, useRef, useEffect } from 'react';
import { DashboardOutlined } from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import Watermark from 'antd-watermark';
import UserInfo from '@stores/userInfo';
import authStore from '@/stores/auth';
import { httpRequest, accountHttp } from '@/utils';
import brand from '@/assets/zd/brand.png';
import brand1 from '@/assets/zd/logo2.png';

const Dashboard = () => {
  const [userName, setUserName] = useState('');
  const getInfo = async () => {
    const res = await accountHttp.get('/admin/account/currentUser/info');
    if (res.code === 0) {
      setUserName(res.data.userName);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Watermark content={`${UserInfo.profile.storeName} - ${UserInfo.profile.userName}`}>
      <div style={{ width: '100%', minHeight: 800, backgroundColor: '#fff' }}>
        <div style={{ position: 'relative', height: '100%' }}>
          <img
            style={{
              maxHeight: 240, maxWidth: 1200, display: 'block', position: 'absolute', left: '27%', top: '30%',
            }}
            src={userName === '任晓洋' ? brand1 : brand}
            alt="华众人力"
          />
        </div>
      </div>
    </Watermark>
  );
};

export default {
  index: true,
  element: <ProtectedRoute allowAccess title="首页" component={<Dashboard />} />,
  options: {
    title: '首页',
    key: 'dashboard',
    icon: <DashboardOutlined />,
    link: '/',
    allowAccess: true,
  },
};
