/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import {
  Upload, Modal, Spin, message, Button,
} from 'antd';
import * as Icon from '@ant-design/icons';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';

const action = () => process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOADFILES_URL;

const DraggerUploadFiles = ({
  fileType, fileList, fileChange, beforeUpload, visible, uploadSubmit,
}) => {
  const { Dragger } = Upload;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [newfileLists, setNewFileFlists] = useState([]);

  // 解析前端file的信息，给到后端
  const parseFiles = (targetFiles) => targetFiles.map((val) => {
    const newFileObj = {};
    newFileObj.fileKey = val.fileKey;
    newFileObj.url = val.url;
    newFileObj.md5 = val.md5;
    newFileObj.name = val.name;
    return newFileObj;
  });
  const checkPicture = (fileName) => /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);

  const handlePreview = async (file) => {
    // if (file.name && checkPicture(file.name)) {
    //   setPreviewImage(file.url || file.preview);
    //   setPreviewVisible(true);
    //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // } else {
    //   window.open(file.url, '_blank');
    // }
  };

  const handleCancel = () => setPreviewVisible(false);
  const onRemoveFiles = (file) => {
    const newFileList = newfileLists.filter((val) => val.uid !== file.uid);
    setNewFileFlists(newFileList);
    fileChange(newFileList);
  };

  const fileRemove = (arry) => {
    const index = arry.findIndex((item) => item.status !== 'uploading');
    if (index > -1) {
      arry.splice(index, 1);
    }
    return arry;
  };

  // 上传文件之前
  const beforeUploadFile = (file, filesList) => {
    const fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
    const fileNameLength = file.name.length;// 取到文件名长度
    const fileFormat = file.name.substring(fileName, fileNameLength);// 截
    const isLt50M = file.size / 1024 / 1024 < 50;
    setIsUpload(true);
    setNewFileFlists(filesList);
    if (!isLt50M) {
      message.error('文件不允许超过50M！');
      return false;
    }
    if (filesList.length > 10) {
      message.error('一次最多可上传十个文档！');
      return false;
    }
    return false;
  };

  const upload = async () => {
    if (!beforeUpload()) {
      return;
    }
    try {
      setLoading(true);
      fileChange(newfileLists);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onChangeFile = (info) => {
    setIsUpload(false);
  };

  useEffect(() => {
    if (isUpload) {
      upload();
    }
  }, [isUpload]);

  const props = {
    listType: 'picture',
    multiple: true,
    maxCount: 10,
    beforeUpload: beforeUploadFile,
    onChange: onChangeFile,
    onRemove: onRemoveFiles,
    // onPreview: handlePreview,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">点击或拖拽文件上传</p>
      <p style={{ fontSize: 12, color: 'red' }}>(一次最多可上传10个文档)</p>
      <p style={{ fontSize: 12, color: 'red' }}>(单个文件不允许超过50M)</p>
    </Dragger>
  );
};
DraggerUploadFiles.propTypes = {
  fileType: PropTypes.string,
  fileList: PropTypes.instanceOf(Array),
  fileChange: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
  uploadSubmit: PropTypes.func, // 上传接口
  visible: PropTypes.bool,
};

DraggerUploadFiles.defaultProps = {
  fileList: [],
  fileType: 'image/*', // image/*
  beforeUpload: () => true,
  uploadSubmit: () => { },
  visible: true,
};

export default DraggerUploadFiles;
