import { inputType } from '@/components';
import { options } from '@/constants';
import { options as optionHooks } from '@/hooks';

export const getOrder = () => [
  {
    name: 'companyIds',
    label: '企业',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      mode: 'multiple',
      options: optionHooks.useCompanies(),
      showSearch: true,
      optionFilterProp: 'label',
    },
  }, {
    name: 'profession',
    label: '工种',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      defaultValue: '',
      options: options.profession,
    },
  }, {
    name: 'ifShelf',
    label: '是否上架',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      defaultValue: '',
      options: options.ifShelf,
    },
  }, {
    name: 'orderStatus',
    label: '订单状态',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      defaultValue: '',
      options: [{ label: '有效', value: true }, { label: '无效', value: false }],
    },
  }, {
    name: 'orderNo',
    label: '订单编号',
    type: inputType.input,
    colSize: 4,
    inputProps: {
      placeholder: '请输入',
      allowClear: true,
    },
  }, {
    name: { name1: 'recruitStart', name2: 'recruitEnd' },
    label: '订单日期',
    type: inputType.datePicker,
    colSize: 6,
    mult: true,
    inputProps: {
      defaultValue: '',
    },
  }, {
    name: { name1: 'orderDurationStart', name2: 'orderDurationEnd' },
    label: '订单工期',
    type: inputType.datePicker,
    colSize: 6,
    mult: true,
    inputProps: {
      defaultValue: '',
    },
  }, {
    name: 'supplierId',
    label: '供应商',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      placeholder: '请输入',
      defaultValue: '',
      options: optionHooks.useSuppliers(),
      showSearch: true,
      optionFilterProp: 'label',
    },
  }, {
    name: 'type',
    label: '结算模式',
    type: inputType.select,
    colSize: 4,
    inputProps: {
      placeholder: '请输入',
      defaultValue: '',
      options: options.settleModelType,
      showSearch: true,
      optionFilterProp: 'label',
    },
  }];

// 订单基本信息
export const getOrderFields = () => [
  {
    name: 'orderName',
    label: '订单名称',
    colSize: 5,
    inputProps: {
      placeholder: '请输入',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'organizeId',
    label: '用工企业',
    type: inputType.select,
    showAllOption: false,
    colSize: 5,
    inputProps: {
      options: optionHooks.useCompanies(),
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'postSequence',
    label: '职位顺序',
    type: inputType.inputNumber,
    colSize: 3,
    inputProps: {
      placeholder: '请输入',
      min: 0,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'post',
    label: '岗位',
    type: inputType.select,
    showAllOption: false,
    colSize: 5,
    inputProps: {
      defaultValue: '',
      options: options.post,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'profession',
    label: '工种',
    type: inputType.select,
    showAllOption: false,
    colSize: 5,
    inputProps: {
      defaultValue: '',
      options: options.profession,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'toPoint',
    label: '我送给谁',
    colSize: 5,
    inputProps: {
      placeholder: '请输入',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: { name1: 'recruitStart', name2: 'recruitEnd' },
    mult: true,
    label: '订单日期',
    colSize: 6,
    type: inputType.datePicker,
    rules: [{
      required: true,
    }],
  }, {
    name: 'orderDuration',
    label: '订单工期',
    colSize: 4,
    type: inputType.datePicker,
    rules: [{
      required: true,
    }],
  }];

export const getBasicFormFields = () => [
  {
    name: 'companyName',
    label: '企业全称',
    inputProps: {
      placeholder: '请输入企业全称',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'shortCompanyName',
    label: '企业简称',
    inputProps: {
      placeholder: '请输入企业简称',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'companyNo',
    label: '企业代号',
    inputProps: {
      placeholder: '请输入企业代号',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'companyIntroduction',
    label: '企业简介',
    inputProps: {
      placeholder: '请输入企业简介',
      allowClear: true,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'scale',
    label: '人员规模',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请选择人员规模',
      options: options.companyScale,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'companyType',
    label: '企业类别',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请选择企业类别',
      options: options.companyType,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'industry',
    label: '所属行业',
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请选择所属行业',
      options: options.companyIndustry,
    },
    rules: [{
      required: true,
    }],
  }, {
    name: 'companyImages',
    label: '企业图片',
    colSize: 24,
  }, {
    name: 'companyGoodTags',
    label: '企业优势标签',
    colSize: 24,
  }, {
    name: 'companyGoodDescription',
    label: '企业优势描述',
    colSize: 24,
    type: inputType.inputTextArea,
    inputProps: {
      rows: 4,
    },
  }, {
    name: 'companyBadTags',
    label: '企业劣势标签',
    colSize: 24,
  }, {
    name: 'companyBadDescription',
    label: '企业劣势描述',
    colSize: 24,
    type: inputType.inputTextArea,
    inputProps: {
      rows: 4,
    },
  }];

export const getPayExplain = () => [
  {
    name: 'payDay',
    label: '发薪日',
    colSize: 5,
    type: inputType.inputNumber,
    inputProps: {
      placeholder: '请输入发薪日',
      style: { width: 200 },
      addonAfter: '号',
      min: 1,
      max: 31,
    },
  }, {
    name: 'payCycleStart',
    label: '发薪周期',
    colSize: 5,
    type: inputType.inputNumber,
    inputProps: {
      placeholder: '开始',
      addonAfter: '号',
      min: 1,
      max: 31,
    },
  }, {
    name: 'payCycleEnd',
    colSize: 5,
    type: inputType.inputNumber,
    inputProps: {
      placeholder: '结束',
      addonAfter: '号',
      min: 1,
      max: 31,
    },
  }];

// 供应商费用
export const getProfitFields = (payroll, setPayroll, needJobOn, setNeedJobOn) => [
  {
    label: '供应商',
    name: 'supplierIds',
    type: inputType.select,
    colSize: 24,
    rules: [{ required: true, message: '请选择供应商' }],
    inputProps: {
      placeholder: '请选择供应商',
      mode: 'multiple',
      allowClear: true,
      options: [{ label: '全部供应商', value: '全部' }].concat(optionHooks.useSuppliers()),
      optionFilterProp: 'label',
    },
  }, {
    label: '企业',
    name: 'companyId',
    type: inputType.select,
    colSize: 12,
    showAllOption: false,
    inputProps: {
      placeholder: '请选择企业',
      allowClear: true,
      options: optionHooks.useCompanies(),
    },
  }, {
    name: 'payroll',
    type: inputType.switch,
    label: '是否代发薪资',
    colSize: 24,
    rules: [{ required: true, message: '请确认是否代发薪资' }],
    inputProps: {
      checkedChildren: '是', unCheckedChildren: '否', checked: payroll, onChange: setPayroll,
    },
  }, {
    name: 'needJobOn',
    type: inputType.switch,
    label: '出名单是否需在职',
    colSize: 24,
    rules: [{ required: true, message: '请确认出名单是否需在职' }],
    inputProps: {
      checkedChildren: '是', unCheckedChildren: '否', checked: needJobOn, onChange: setNeedJobOn,
    },
  }, {
    name: 'profitRemark',
    type: inputType.inputTextArea,
    label: '费用说明',
    colSize: 16,
  }];

// 会员工价说明
export const getEmployee = () => [
  {
    name: 'explain',
    label: '工价详情',
    type: inputType.inputTextArea,
    colSize: 16,
    inputProps: {
      rows: 4,
    },
    rules: [{
      required: true,
    }, {
      pattern: /.{6,}/gm,
      message: '最少6个字符',
    }],
  }, {
    name: 'simpleExplain',
    label: '工价说明',
    type: inputType.inputTextArea,
    colSize: 16,
    inputProps: {
      rows: 4,
    },
    rules: [{
      required: true,
    }, {
      pattern: /.{6,}/gm,
      message: '最少6个字符',
    }],
  }, {
    name: 'debtType',
    label: '借支类型',
    colSize: 24,
    type: inputType.radioGroup,
    inputProps: {
      options: options.salary,
    },
  },
  {
    name: 'eat',
    label: '就餐',
    colSize: 5,
    type: inputType.select,
    inputProps: {
      options: options.companyFood,
    },
  }, {
    name: 'stay',
    label: '住宿',
    colSize: 5,
    type: inputType.select,
    inputProps: {
      options: options.dormitory,
    },
  }, {
    name: 'waterElectricity',
    label: '水电费',
    colSize: 5,
    type: inputType.select,
    inputProps: {
      options: options.waterElectricity,
    },
  }, {
    name: 'commercial',
    label: '购买商保',
    colSize: 5,
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }, {
    name: 'socialSecurity',
    label: '购买社保',
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }, {
    name: 'leaveSalary',
    label: '自离薪资',
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }, {
    name: 'memberAward',
    label: '会员入职奖',
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }, {
    name: 'physicalExamination',
    label: '体检费',
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }, {
    name: 'remark',
    label: '备注/说明',
    colSize: 10,
    type: inputType.inputTextArea,
    inputProps: {
      placeholder: '请输入',
    },
  }];

// 接单政策要求
export const getOrdeRecePolicy = () => [
  {
    name: 'applyPolicyRemark',
    label: '接单政策文字说明',
    type: inputType.inputTextArea,
    colSize: 16,
    inputProps: {
      rows: 4,
    },
    rules: [{
      required: true,
    }, {
      pattern: /.{6,}/gm,
      message: '最少6个字符',
    }],
  },
  {
    name: 'applyPolicyImage',
    label: '接单政策照片',
    colSize: 6,
  }, {
    name: 'applyPolicyTaker',
    label: '接单人',
    type: inputType.select,
    colSize: 6,
    inputProps: {
      options: optionHooks.useAllUsers().options,
      showSearch: true,
      optionFilterProp: 'label',
      mode: 'multiple',
    },
  }];

export const getWorkEnv = () => [
  {
    name: 'shiftCategory',
    label: '班别',
    type: inputType.radioGroup,
    inputProps: {
      options: options.companyShift,
    },
  }, {
    name: 'sitStand',
    label: '站坐',
    type: inputType.radioGroup,
    inputProps: {
      options: options.sitStand,
    },
  }, {
    name: 'dress',
    label: '着装',
    type: inputType.radioGroup,
    colSize: 8,
    inputProps: {
      options: options.dress,
    },
  }, {
    name: 'line',
    label: '产线',
    type: inputType.radioGroup,
    inputProps: {
      options: options.companyLine,
    },
  }, {
    name: 'microscope',
    label: '显微镜',
    type: inputType.radioGroup,
    inputProps: {
      options: options.microscope,
    },
  }, {
    name: 'dormitory',
    label: '住宿',
    type: inputType.radioGroup,
    inputProps: {
      options: options.dormitory,
    },
  }, {
    name: 'food',
    label: '伙食',
    type: inputType.radioGroup,
    colSize: 8,
    inputProps: {
      options: options.companyFood,
    },
  }, {
    name: 'phone',
    label: '车间带手机',
    type: inputType.radioGroup,
    inputProps: {
      options: options.companyPhone,
    },
  }];

export const getEmployCondition = () => [
  {
    name: 'idCard',
    label: '身份证',
    type: inputType.radioGroup,
    colSize: 9,
    inputProps: {
      options: options.companyIdCard,
    },
  }, {
    name: 'english',
    label: '英文字母',
    type: inputType.radioGroup,
    inputProps: {
      options: options.companyEnglish,
    },
  }, {
    name: 'returnFactory',
    label: '返厂',
    type: inputType.radioGroup,
    colSize: 8,
    inputProps: {
      options: options.returnFactory,
    },
  }, {
    name: 'tattooSmoke',
    label: '纹身烟疤',
    type: inputType.radioGroup,
    inputProps: {
      options: options.tattooSmoke,
    },
  }, {
    name: 'nationality',
    label: '名族限制',
    type: inputType.radioGroup,
    colSize: 8,
    inputProps: {
      options: options.companyNationality,
    },
  }, {
    name: 'background',
    label: '案底',
    type: inputType.radioGroup,
    colSize: 6,
    inputProps: {
      options: options.background,
    },
  }, {
    name: 'studentProve',
    label: '学生证明',
    type: inputType.radioGroup,
    colSize: 6,
    inputProps: {
      options: options.studentProve,
    },
  }, {
    name: 'height',
    label: '身高要求',
    colSize: 6,
    inputProps: {
      placeholder: '请输入身高要求',
    },
  }, {
    name: 'examine',
    label: '体检要求',
    colSize: 6,
    inputProps: {
      placeholder: '请输入体检要求',
    },
  }, {
    name: 'remarks',
    label: '备注',
    colSize: 6,
    inputProps: {
      placeholder: '请输入备注',
    },
  }];

export const getInterviewCondition = () => [
  {
    name: 'idCardCopy',
    label: '身份证复印件',
    colSize: 6,
    inputProps: {
      placeholder: '请输入身份证复印件要求',
    },
  }, {
    name: 'graduateCopy',
    label: '毕业证复印件',
    colSize: 6,
    inputProps: {
      placeholder: '请输入毕业证复印件要求',
    },
  }, {
    name: 'photo',
    label: '照片',
    colSize: 6,
    inputProps: {
      placeholder: '请输入照片要求',
    },
  }];

export const getPreventCondition = () => [
  {
    name: 'itineraryCode',
    label: '行程码',
    colSize: 6,
    inputProps: {
      placeholder: '请输入行程码要求',
    },
  }, {
    name: 'nucleicAcid',
    label: '核酸',
    colSize: 6,
    inputProps: {
      placeholder: '请输入核酸要求',
    },
  }, {
    name: 'vaccination',
    label: '疫苗接种',
    colSize: 6,
    inputProps: {
      placeholder: '请输入疫苗接种要求',
    },
  }];

export const getContactFields = () => [
  {
    name: 'contactResidents',
    label: '驻厂对接人',
    colSize: 8,
  }, {
    name: 'contactBusiness',
    label: '商务对接人',
    colSize: 8,
  }, {
    name: 'contactFinances',
    label: '财务对接人',
    colSize: 8,
  }];

export const getPosterTempFields = () => [
  {
    name: 'posterTemp',
    label: '海报模板',
    colSize: 24,
  }];

export const getResidentPerson = (residentPersonOptions) => [
  {
    name: 'residents',
    label: '驻厂人员',
    colSize: 16,
    type: inputType.select,
    showAllOption: false,
    inputProps: {
      placeholder: '请输入或选择驻厂人员',
      allowClear: true,
      options: residentPersonOptions,
      mode: 'multiple',
      showSearch: true,
      optionFilterProp: 'label',
    },
  }];

export default {};
