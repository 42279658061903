import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const RoleManagement = lazy(() => import('./roleManagement'));
const AccountManagement = lazy(() => import('./accountManagement'));

export default {
  path: 'setting',
  element: <Outlet />,
  options: {
    title: '系统管理',
    permissionKey: 'menu-systemManage',
    key: 'menu-systemManage',
    icon: <Icon.DesktopOutlined />,
    link: '/setting',
  },
  children: [
    {
      path: 'accountManagement',
      element: <ProtectedRoute permissionKey="menu-systemManage-accountManage" title="账号管理" component={<AccountManagement />} />,
      options: {
        title: '账号管理',
        permissionKey: 'menu-systemManage-accountManage',
        key: 'menu-systemManage-accountManage',
        icon: null,
        link: '/setting/accountManagement',
      },
    },
    {
      path: 'roleManagement',
      element: <ProtectedRoute permissionKey="menu-systemManage-roleManage" title="角色管理" component={<RoleManagement />} />,
      options: {
        title: '角色管理',
        permissionKey: 'menu-systemManage-roleManage',
        key: 'menu-systemManage-roleManage',
        icon: null,
        link: '/setting/roleManagement',
      },
    },
  ],
};
