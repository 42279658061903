import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import CopySupplier from '@modules/supplier/order/orderManagement/copySupplier';

const SupplierList = lazy(() => import('@/modules/supplier/supplier'));
const OrderManagement = lazy(() => import('@/modules/supplier/order/orderManagement/orderManagement'));
const OrderEdit = lazy(() => import('@/modules/supplier/order/orderEdit'));
const OrderNew = lazy(() => import('@/modules/supplier/order/orderNew'));
export default {
  path: 'supplier',
  element: <Outlet />,
  options: {
    title: '供应商管理',
    permissionKey: 'menu-supplier',
    key: 'menu-supplier',
    icon: <Icon.ContactsOutlined />,
    link: '/supplier',
  },
  children: [
    {
      path: 'list',
      element: <Outlet />,
      options: {
        title: '供应商',
        key: 'menu-supplier-list',
        icon: null,
        link: '/supplier/list',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="供应商" permissionKey="menu-supplier-list" component={<SupplierList />} />,
        },
      ],
    },
    {
      path: 'orderManagement',
      element: <Outlet />,
      options: {
        title: '供应商订单',
        key: 'menu-supplier-orderManage',
        icon: null,
        link: '/supplier/orderManagement',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="供应商订单管理" permissionKey="menu-supplier-orderManage" component={<OrderManagement />} />,
        },
        {
          path: 'create',
          element: <ProtectedRoute title="新增供应商订单" permissionKey="menu-supplier-orderManage" component={<OrderNew />} />,
        },
        {
          path: ':orderId/edit',
          element: <ProtectedRoute title="编辑供应商订单" permissionKey="menu-supplier-orderManage" component={<OrderEdit />} />,
        },
      ],
    },
    // {
    //   path: 'roster',
    //   element: <Outlet />,
    //   options: {
    //     title: '供应商名单',
    //     key: 'menu-supplier-roster',
    //     icon: null,
    //     link: '/supplier/roster',
    //   },
    //   children: [
    //     {
    //       index: true,
    //       element: <ProtectedRoute title="供应商名单" permissionKey="menu-supplier-list" component={<SupplierRoster />} />,
    //     },
    //   ],
    // },
  ],
};
