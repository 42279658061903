export const getMenusFromRoutes = (routes, permissions, path = '') => {
  const menus = [];
  let currentMenuKeys = [];
  let childrenArry = [];
  routes.forEach(({ options, children }) => {
    if (!options?.key) {
      return;
    }
    if (options?.link === path || (options?.link !== '/' && path.indexOf(options?.link) === 0)) {
      currentMenuKeys.push(options.key);
    }
    if (!options.permissionKey || options.allowAccess || permissions?.includes(options.permissionKey)) {
      const menu = {
        ...options,
        visible: options.displayInMenu !== false,
      };
      if (children?.length) {
        const childRes = getMenusFromRoutes(children, permissions, path);
        if (childRes?.menus) {
          childrenArry = childRes?.menus.filter((item) => (permissions?.includes(item.key) || options.allowAccess));
        }
        // menu.children = childRes?.menus;
        menu.children = childrenArry;
        currentMenuKeys = currentMenuKeys.concat(childRes?.currentMenuKeys || []);
      }
      menus.push(menu);
    }
  });
  return { menus, currentMenuKeys };
};

export default {};
