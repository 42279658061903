import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const CompanyList = lazy(() => import('@/modules/businessAffairs/company/list'));
const CompanyCreact = lazy(() => import('@/modules/businessAffairs/company/companyCreact'));
const CompanyEdit = lazy(() => import('@/modules/businessAffairs/company/companyEdit'));
const OrderManagement = lazy(() => import('@/modules/businessAffairs/order/orderManagement/orderManagement'));
const OrderEdit = lazy(() => import('@/modules/businessAffairs/order/orderEdit'));
const OrderNew = lazy(() => import('@/modules/businessAffairs/order/orderNew'));
const CustomerVisit = lazy(() => import('@/modules/businessAffairs/customerVisit'));
export default {
  path: 'businessAffairs',
  element: <Outlet />,
  options: {
    title: '商务管理',
    permissionKey: 'menu-affairsManage',
    key: 'menu-affairsManage',
    icon: <Icon.WhatsAppOutlined />,
    link: '/businessAffairs',
    // allowAccess: true,
  },
  children: [
    {
      path: 'company',
      element: <Outlet />,
      options: {
        title: '企业管理',
        key: 'menu-affairsManage-companyManage',
        icon: null,
        link: '/businessAffairs/company',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="企业管理" permissionKey="menu-affairsManage-companyManage" component={<CompanyList />} />,
        },
        {
          path: 'create',
          element: <ProtectedRoute title="新增企业" permissionKey="menu-affairsManage-companyManage" component={<CompanyCreact />} />,
        },
        {
          path: ':companyId/edit',
          element: <ProtectedRoute title="编辑企业" permissionKey="menu-affairsManage-companyManage" component={<CompanyEdit />} />,
        },
      ],
    },
    {
      path: 'orderManagement',
      element: <Outlet />,
      options: {
        title: '订单管理',
        key: 'menu-affairsManage-orderManage',
        icon: null,
        link: '/businessAffairs/orderManagement',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="订单管理" permissionKey="menu-affairsManage-orderManage" component={<OrderManagement />} />,
        },
        {
          path: 'create',
          element: <ProtectedRoute title="新增订单" permissionKey="menu-affairsManage-orderManage" component={<OrderNew />} />,
        },
        {
          path: ':orderId/edit',
          element: <ProtectedRoute title="编辑订单" permissionKey="menu-affairsManage-orderManage" component={<OrderEdit />} />,
        },
      ],
    },
    {
      path: 'customerVisit',
      element: <Outlet />,
      options: {
        title: '客户拜访',
        key: 'menu-affairsManage-customerVisit',
        icon: null,
        link: '/businessAffairs/customerVisit',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="客户拜访" permissionKey="menu-affairsManage-customerVisit" component={<CustomerVisit />} />,
        },
      ],
    },
  ],
};
