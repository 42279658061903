/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import {
  Button, Card, Divider, Form, message, Col, Select,
} from 'antd';
import { getProfitFields } from '@modules/supplier/order/formItem';
import PropTypes from 'prop-types';
import SupplierProfit from '@modules/supplier/order/decision/supplierProfit';
import decisionCovert from '@modules/supplier/order/decision/decisionCovert';
import { options as hookOptions, options as optionHooks } from '@hooks';
import { FormItem } from '@/components';
import { httpRequest } from '@/utils';

const SupplierProfitForm = ({ index, sourceOrderId, onAfterSubmit }) => {
  const [profitForm] = Form.useForm(); // 供应商费用表单实例
  const [payroll, setPayroll] = useState(true);
  const [needJobOn, setNeedJobOn] = useState(true);
  const [profitData, setProfitData] = useState([]);
  const profitFields = getProfitFields(payroll, setPayroll, needJobOn, setNeedJobOn);
  const [hidden, setHidden] = useState();
  const supplierData = [{ label: '全部供应商', value: '全部' }].concat(optionHooks.useSuppliers()); // 供应商数据

  // 取消选择函数
  const onDeselect = (value, option) => {
    const newSupplierId = profitForm.getFieldsValue();
    if (value === '全部') {
      profitForm.setFieldsValue({ supplierIds: [] });
    }
    if ((newSupplierId?.supplierIds?.indexOf('全部') > -1) && (value !== '全部')) {
      const supplierId = newSupplierId?.supplierIds?.filter((item) => item !== '全部');
      profitForm.setFieldsValue({ supplierIds: supplierId });
    }
  };

  // 选择的函数
  const onSelect = (value) => {
    const newSupplierId = profitForm.getFieldsValue();
    if (value === '全部') {
      const supplierId = [];
      supplierData?.map((ids) => {
        supplierId.push(ids.value);
        return ids;
      });
      profitForm.setFieldsValue({ supplierIds: supplierId });
    }
    if ((newSupplierId?.supplierIds?.indexOf('全部') === -1) && (value !== '全部') && (newSupplierId?.supplierIds?.length === supplierData?.length - 1)) {
      const supplierId = newSupplierId?.supplierIds?.concat('全部');
      profitForm.setFieldsValue({ supplierIds: supplierId });
    }
  };

  const onSubmitProfitForm = async (vals) => {
    try {
      const rules = decisionCovert.profit2Api(profitData);
      const supplierIdArry = vals?.supplierIds?.filter((item) => item !== '全部');
      const param = {
        orderId: sourceOrderId,
        ...vals,
        profitRemark: vals.profitRemark?.replace(/\n/g, '<br/>'),
        rules,
        supplierIds: supplierIdArry,
      };
      const res = await httpRequest.post('/admin/order/supplier/copy', param);
      if (res.code === 0) {
        message.info('提交成功');
        setHidden(true);
        onAfterSubmit();
      }
    } catch (err) {
      message.error(err?.message || '提交失败');
    }
  };

  return (
    <div hidden={hidden}>
      <Divider orientation="left">{`第${index}条`}</Divider>
      <Form
        // labelCol={{ span: 2 }}
        form={profitForm}
        initialValues={{ needJobOn: true, payroll: true }}
        onFinish={onSubmitProfitForm}
      >
        <Card>
          {
            profitFields.map((f) => {
              if (f.name === 'supplierIds') {
                return (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item key={f.name} name={f.name} label={f.label} rules={[{ required: true }]}>
                      <Select
                        options={supplierData}
                        mode="multiple"
                        optionFilterProp="label"
                        onDeselect={onDeselect}
                        onSelect={onSelect}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return (
                <FormItem
                  name={f.name}
                  label={f.label}
                  type={f.type}
                  inputProps={f.inputProps}
                  rules={f.rules}
                  showAllOption={f.showAllOption}
                />
              );
            })
          }
          <SupplierProfit setProfitData={setProfitData} />
        </Card>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">提交</Button>
        </div>
      </Form>
      <div style={{ backgroundColor: '#000000', width: '100%', height: 1 }} />
    </div>
  );
};

SupplierProfitForm.propTypes = {
  index: PropTypes.number,
  sourceOrderId: PropTypes.string,
  onAfterSubmit: PropTypes.func,
};

SupplierProfitForm.defaultProps = {
  index: 1,
  sourceOrderId: '',
  onAfterSubmit: () => {},
};

export default SupplierProfitForm;
