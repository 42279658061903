import { makeAutoObservable, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { accountHttp } from '@/utils';
import { storageKeys } from '@/constants';

const hasLoggedIn = !!Cookies.get(storageKeys.token);

class UserInfo {
  profile = {
    userName: '',
    storeName: '',
    source: '',
    systemIdentities: [],
  }

  isLoggedIn = hasLoggedIn

  constructor() {
    makeAutoObservable(this);
    if (hasLoggedIn) {
      this.getProfile();
    }
  }

  setProfile(userName, storeName, source, systemIdentities) {
    this.profile.userName = userName;
    this.profile.storeName = storeName;
    this.profile.source = source;
    this.profile.systemIdentities = systemIdentities;
  }

  async getProfile() {
    try {
      const userInfoRes = await accountHttp.get('/admin/account/currentUser/info');
      if (userInfoRes.code === 0) {
        runInAction(() => {
          const { data } = userInfoRes;
          this.setProfile(data.userName, data.storeName, data.source, data?.systemIdentities);// 暂时还没有头像
        });
      }
    } catch (err) {
      console.log(err?.message || '获取用户信息失败');
    }
  }

  clearlogInfo() {
    this.profile = {
      userName: '',
      storeName: '',
    };
    this.isLoggedIn = false;
  }
}

export default new UserInfo();
