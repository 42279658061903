import React, { useEffect, useState } from 'react';
import DecisionSelect from '@modules/supplier/order/decision/decisionSelect';
import {
  Button, ConfigProvider, DatePicker, Form, Radio, Row, Select, Space, Switch, Table,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { MinusCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import random from '@utils/random';
import PropTypes from 'prop-types';
import decisionCovert from '@modules/supplier/order/decision/decisionCovert';
import { httpRequest } from '@/utils';
import { inputType } from '@/components';

const dePaMap = {
  punch_day: {
    inTy: inputType.inputNumber, dePa: 1, after: '天', min: 0,
  },
  punch_hour: {
    inTy: inputType.inputNumber, dePa: 4, after: '小时', min: 0,
  },
  work_day: {
    inTy: inputType.inputNumber, dePa: 1, after: '天', min: 0,
  },
  work_hour: {
    inTy: inputType.inputNumber, dePa: 4, after: '小时', min: 0,
  },
  job: { inTy: inputType.datePicker, dePa: moment().format('YYYY-MM-DD') },
};

const defaultPunchRule = () => ({
  options: [...decisionCovert.genderSelect, ...decisionCovert.noneGenderSelect],
  initData: [{
    key: random(),
    type: 'gender',
    value: 'none',
    child: {
      type: 'next',
      data: {
        options: [...decisionCovert.punchDaySelect, ...decisionCovert.punchHourSelect, ...decisionCovert.workDaySelect,
          ...decisionCovert.workHourSelect, ...decisionCovert.jobSelect, ...decisionCovert.nonePunchSelect],
        initData: [
          {
            key: random(),
            type: 'none',
            value: 'none',
            child: { type: 'supplierProfitResult', data: { settlementType: 'punch', settlementSelect: 'WAGE', num: 0 } },
          },
        ],
      },
    },
  },
  ],
});

const defaultDateRule = () => ({
  options: [...decisionCovert.genderSelect, ...decisionCovert.noneGenderSelect],
  initData:
    [
      {
        key: random(),
        type: 'none',
        value: 'none',
        child: {
          type: 'next',
          data: {
            options: [...decisionCovert.punchDaySelect, ...decisionCovert.punchHourSelect, ...decisionCovert.workDaySelect,
              ...decisionCovert.workHourSelect, ...decisionCovert.jobSelect, ...decisionCovert.noneSelect],
            initData: [
              {
                key: random(),
                type: 'none',
                value: 'none',
                child: { type: 'supplierProfitResult', data: { settlementType: 'date', settlementSelect: 'REBATE', num: 0 } },
              },
            ],
          },
        },
      },
    ],
});

const SupplierProfit = ({
  orderId,
  setProfitData,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (orderId) {
      httpRequest.get(`/admin/supplierProfit/${orderId}`).then((result) => {
        const coverData = decisionCovert.api2Profit(result.data);
        setData(coverData);
      });
    }
  }, [orderId]);
  useEffect(() => {
    setProfitData(data);
  }, [data]);
  const upPunchDecisionData = (treeData, index) => {
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      copyData[index].punchDecision = treeData;
      return copyData;
    });
  };
  const upDateDecisionData = (treeData, index) => {
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      copyData[index].dateDecision = treeData;
      return copyData;
    });
  };
  const handleDateChange = (index, startOrEnd, date) => {
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      copyData[index][startOrEnd] = date?.valueOf();
      if (moment(copyData[index].dateStart) >= moment(copyData[index].dateEnd)) {
        copyData[index].dateEnd = copyData[index].dateStart;
      }
      return copyData;
    });
  };
  const handleDelete = (index) => {
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      return copyData.filter((item, i) => index !== i);
    });
  };
  const handleAdd = () => {
    const date = data.length > 0 ? moment(data[data.length - 1].dateEnd).startOf('day').add(1, 'days').valueOf()
      : moment().startOf('day').valueOf();
    const startDate = moment().valueOf();
    const endDate = moment().subtract('years').add(5, 'years').valueOf();
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      copyData.push({
        key: random(),
        dateStart: orderId ? date : startDate,
        dateEnd: orderId ? date : endDate,
      });
      return copyData;
    });
  };
  const renderEmpty = () => <Button type="link" onClick={() => handleAdd()}>添加供应商费用规则</Button>;
  const columns = [
    {
      title: '适用日期',
      key: 'dateStart',
      width: 150,
      dataIndex: 'dateStart',
      // fixed: 'left',
      render: (text, item, index) => (
        <>
          <div>
            <DatePicker
              allowClear={false}
              disabledDate={(current) => {
                if (data[index + 1]) {
                  return true;
                }
                if (!data[index - 1]?.dateStart) {
                  return false;
                }
                return moment(data[index - 1].dateEnd).add(1, 'days').format('YYYY-MM-DD') !== current.format('YYYY-MM-DD');
              }}
              value={moment(item.dateStart)}
              onChange={(date) => {
                handleDateChange(index, 'dateStart', date);
              }}
            />
          </div>
          ~
          <div>
            <DatePicker
              allowClear={false}
              disabledDate={(current) => {
                if (data[index + 1] || !item.dateStart) {
                  return true;
                }
                return moment(item.dateStart) > current;
              }}
              value={moment(item.dateEnd)}
              onChange={(date) => {
                handleDateChange(index, 'dateEnd', date);
              }}
            />
          </div>
        </>
      ),
    },
    {
      title: '结算模式',
      key: 'settleModel',
      width: 150,
      dataIndex: 'settleModel',
      render: (text, { settleModel }, index) => (
        <Radio.Group
          onChange={(e) => {
            setData((preData) => {
              const copyData = _.cloneDeep(preData);
              copyData[index].settleModel = e.target.value;
              return copyData;
            });
          }}
          value={settleModel}
        >
          <Space direction="vertical">
            <Radio value="STUB">短线</Radio>
            <Radio value="LONG_LINE">长线</Radio>
            <Radio value="PACKAGE_PRICE">打包价</Radio>
            <Radio value="AGENT">代理费</Radio>
            <Radio value="OTHER">其他</Radio>
          </Space>
        </Radio.Group>
      ),
    },
    {
      title: '费用1',
      key: 'punchDecision',
      width: 675,
      dataIndex: 'punchDecision',
      render: (text, { punchDecision }, index) => {
        if (!punchDecision) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                type="link"
                onClick={() => {
                  setData((preData) => {
                    const copyData = _.cloneDeep(preData);
                    copyData[index].punchDecision = defaultPunchRule();
                    return copyData;
                  });
                }}
              >
                添加费用1
              </Button>
            </div>
          );
        }
        return (
          <Row>
            <DecisionSelect
              sourceData={punchDecision}
              upSourceData={(treeData) => {
                upPunchDecisionData(treeData, index);
              }}
              dePaMap={dePaMap}
              root
              autoGen
            />
            <div style={{ margin: 'auto' }}>
              <MinusCircleTwoTone
                style={{ fontSize: 11 }}
                onClick={() => {
                  setData((preData) => {
                    const copyData = _.cloneDeep(preData);
                    copyData[index].punchDecision = null;
                    return copyData;
                  });
                }}
              />
            </div>
          </Row>

        );
      },
    },
    {
      title: '费用2',
      key: 'dateDecision',
      width: 675,
      dataIndex: 'dateDecision',
      render: (text, { dateDecision }, index) => {
        if (!dateDecision) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                type="link"
                onClick={() => {
                  setData((preData) => {
                    const copyData = _.cloneDeep(preData);
                    copyData[index].dateDecision = defaultDateRule();
                    return copyData;
                  });
                }}
              >
                添加费用2
              </Button>
            </div>
          );
        }
        return (
          <Row>
            <DecisionSelect
              sourceData={dateDecision}
              upSourceData={(treeData) => {
                upDateDecisionData(treeData, index);
              }}
              dePaMap={dePaMap}
              root
              autoGen
            />
            <div style={{ margin: 'auto' }}>
              <MinusCircleTwoTone
                style={{ fontSize: 11 }}
                onClick={() => {
                  setData((preData) => {
                    const copyData = _.cloneDeep(preData);
                    copyData[index].dateDecision = null;
                    return copyData;
                  });
                }}
              />
            </div>
          </Row>
        );
      },
    },
    {
      title: '操作',
      key: 'operation',
      width: 85,
      render: (text, record, index) => (
        <Space>
          <Button type="link" onClick={() => handleDelete(index)}>删除</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 1700,
            y: 500,
          }}
          bordered
        />
      </ConfigProvider>
      <div style={{ textAlign: 'center', marginTop: 5, display: data.length === 0 ? 'none' : 'block' }}>
        <PlusSquareTwoTone style={{ fontSize: 20 }} onClick={() => { handleAdd(); }} />
      </div>
    </>
  );
};

SupplierProfit.propTypes = {
  orderId: PropTypes.string,
  setProfitData: PropTypes.func,
};

SupplierProfit.defaultProps = {
  orderId: '',
  setProfitData: () => { },
};

export default SupplierProfit;
