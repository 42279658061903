import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const CommissionAccount = lazy(() => import('@/modules/myCommission/commissionAccount'));
const CommissionDetails = lazy(() => import('@/modules/myCommission/commissionDetails'));

export default {
  path: 'myCommission',
  element: <Outlet />,
  options: {
    title: '我的提成',
    permissionKey: 'menu-myReward',
    key: 'myCommission',
    icon: <Icon.FileTextOutlined />,
    link: '/myCommission',
    // allowAccess: true,
  },
  children: [
    {
      path: 'commissionAccount',
      element: <Outlet />,
      options: {
        title: '提成核算',
        key: 'menu-myReward-count',
        icon: null,
        link: '/myCommission/commissionAccount',
        // allowAccess: true,
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="提成核算" permissionKey="menu-myReward-count" component={<CommissionAccount />} />,
        },
      ],
    },
    {
      path: 'commissionDetails',
      element: <Outlet />,
      options: {
        title: '提成明细',
        key: 'menu-myReward-detail',
        icon: null,
        link: '/myCommission/commissionDetails',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="提成明细" permissionKey="menu-myReward-detail" component={<CommissionDetails />} />,
        },
      ],
    },
  ],
};
