import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAliveController } from 'react-activation';
import { CloseCircleOutlined } from '@ant-design/icons';

import './index.less';

const Tab = ({ node }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getCachingNodes, dropScope } = useAliveController();
  const cachingNodes = getCachingNodes();
  const closable = cachingNodes.length > 1;

  const dropTab = (e) => {
    e.stopPropagation();
    const currentName = node.name;
    if (location.pathname === node.name) {
      dropScope(currentName);
      // 前往排除当前 node 后的最后一个 tab
      navigate(
        cachingNodes.filter((nodeVal) => nodeVal.name !== currentName).pop().name,
      );
    } else {
      dropScope(currentName);
    }
  };

  const goTo = () => {
    navigate(node.name);
  };

  return (
    <li
      className={location.pathname === node.name ? 'active' : ''}
      onClick={goTo}
      onKeyPress={goTo}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
    >
      {node.cacheKey}
      {
        closable && (
        <CloseCircleOutlined
          className="close-btn"
          onClick={dropTab}
        />
        )
      }
    </li>
  );
};
export default Tab;
