import React, { useEffect, useState } from 'react';
import {
  Card, InputNumber, Select, Space,
} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

const InputNumStyle = {
  width: 150,
};

const afterMap = {
  WAGE: '元/小时',
  BASIC_SALARY: '元/月',
  DAY_SALARY: '元/天',
  DAY_SETTLEMENT: '元/天',
  SPREAD: '元/小时',
  REBATE: '元',
  STUB: '元/人',
  LONG_LINE: '元/天/人',
  FILL: '元/小时',
  SUBSIDY: '元/小时',
  STABLE: '元/小时',
  PACKAGE_PRICE: '元/小时',
  AGENT: '元/小时',
};
const settlementPunchOpt = [{ label: '工价', value: 'WAGE' }, { label: '底薪', value: 'BASIC_SALARY' },
  { label: '日薪', value: 'DAY_SALARY' }, { label: '日结', value: 'DAY_SETTLEMENT' }];
const settlementDateOpt = [{ label: '返费', value: 'REBATE' }, { label: '差价', value: 'SPREAD' }, { label: '补足', value: 'FILL' },
  { label: '补贴', value: 'SUBSIDY' }, { label: '稳岗', value: 'STABLE' }];
const profitPunchOpt = [{ label: '工价', value: 'WAGE' }, { label: '底薪', value: 'BASIC_SALARY' },
  { label: '打包价', value: 'PACKAGE_PRICE' }, { label: '代理费', value: 'AGENT' }, { label: '返费', value: 'REBATE' }];
const profitDateOpt = [{ label: '返费', value: 'REBATE' }, { label: '差价', value: 'SPREAD' }];

const DecisionResult = ({ type, sourceData, upSourceData }) => {
  const [data, setData] = useState(sourceData);

  useEffect(() => {
    upSourceData(data);
  }, [data]);

  const handleNumChange = (value, attr) => {
    setData((preData) => {
      const copyData = _.cloneDeep(preData);
      if (attr) copyData.num[attr] = value;
      else copyData.num = value;
      return copyData;
    });
  };

  switch (type) {
    case 'settlementResult': {
      return (
        <>
          <div>
            <Select
              style={{ width: 75, display: 'left' }}
              options={(data.settlementType === 'punch') ? settlementPunchOpt : settlementDateOpt}
              value={data.settlementSelect}
              onChange={(value) => {
                setData((preData) => {
                  const copyData = _.cloneDeep(preData);
                  copyData.settlementSelect = value;
                  return copyData;
                });
              }}
            />
            <InputNumber
              min={0}
              controls={false}
              style={{ width: 120, display: 'left' }}
              value={data.num}
              addonAfter={afterMap[data.settlementSelect]}
              onChange={(value) => { handleNumChange(value); }}
            />
          </div>
          <div style={{ clear: 'both' }} />
        </>
      );
    }
    case 'empRewardResult': {
      return (
        <Space size="small">
          <span>招聘员:</span>
          <InputNumber
            min={0}
            style={InputNumStyle}
            addonAfter={afterMap[data.empRewardModel]}
            value={data.num.recruiter}
            onChange={(value) => { handleNumChange(value, 'recruiter'); }}
          />
          <span>组长:</span>
          <InputNumber
            min={0}
            style={InputNumStyle}
            addonAfter={afterMap[data.empRewardModel]}
            value={data.num.groupLeader}
            onChange={(value) => { handleNumChange(value, 'groupLeader'); }}
          />
          <span>店长:</span>
          <InputNumber
            min={0}
            style={InputNumStyle}
            addonAfter={afterMap[data.empRewardModel]}
            value={data.num.storeLeader}
            onChange={(value) => { handleNumChange(value, 'storeLeader'); }}
          />
        </Space>
      );
    }
    case 'fissionRewardResult': {
      return (
        <>
          <Card title="外部人员" size="small">
            <Space size="small">
              <span>推荐人:</span>
              <InputNumber
                min={0}
                style={InputNumStyle}
                addonAfter="元"
                value={data.num.recommender}
                onChange={(value) => { handleNumChange(value, 'recommender'); }}
              />
              <span>被推荐人:</span>
              <InputNumber
                min={0}
                style={InputNumStyle}
                addonAfter="元"
                value={data.num.referee}
                onChange={(value) => { handleNumChange(value, 'referee'); }}
              />
            </Space>
          </Card>
          <Card title="内部人员" size="small">
            <Space size="small">
              <span>招聘员:</span>
              <InputNumber
                min={0}
                style={InputNumStyle}
                addonAfter="元"
                value={data.num.recruiter}
                onChange={(value) => { handleNumChange(value, 'recruiter'); }}
              />
              <span>组长:</span>
              <InputNumber
                min={0}
                style={InputNumStyle}
                addonAfter="元"
                value={data.num.groupLeader}
                onChange={(value) => { handleNumChange(value, 'groupLeader'); }}
              />
              <span>店长:</span>
              <InputNumber
                min={0}
                style={InputNumStyle}
                addonAfter="元"
                value={data.num.storeLeader}
                onChange={(value) => { handleNumChange(value, 'storeLeader'); }}
              />
            </Space>
          </Card>
        </>
      );
    }
    case 'supplierProfitResult': {
      return (
        <>
          <div>
            <Select
              style={{ width: 85, display: 'left' }}
              options={(data.settlementType === 'punch') ? profitPunchOpt : profitDateOpt}
              value={data.settlementSelect}
              onChange={(value) => {
                setData((preData) => {
                  const copyData = _.cloneDeep(preData);
                  copyData.settlementSelect = value;
                  return copyData;
                });
              }}
            />
            <InputNumber
              min={0}
              controls={false}
              style={{ width: 120, display: 'left' }}
              value={data.num}
              addonAfter={afterMap[data.settlementSelect]}
              onChange={(value) => { handleNumChange(value); }}
            />
          </div>
          <div style={{ clear: 'both' }} />
        </>
      );
    }
    default: {
      return {};
    }
  }
};

DecisionResult.propTypes = {
  type: PropTypes.string,
  sourceData: PropTypes.shape({
    settlementSelect: PropTypes.string,
    settlementType: PropTypes.string,
    empRewardModel: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    num: PropTypes.any,
  }),
  upSourceData: PropTypes.func,
};

DecisionResult.defaultProps = {
  type: '',
  sourceData: {},
  upSourceData: () => {},
};

export default DecisionResult;
