import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { globalStyles } from '@styles';
import {
  List,
  Modal,
} from 'antd';
import Search from 'antd/lib/input/Search';

const { Content } = globalStyles;

const ListModal = ({
  title,
  listModalVisible,
  data, // 列表数据
  renderItem, // 渲染内容
  searchAttr, // 搜索字段名
  footer,
  onOk,
  onCancel,
}) => {
  const [dataSource, setDataSource] = useState();
  useEffect(() => { setDataSource(data); }, [data]);

  const handleChange = (e) => {
    const val = e?.target?.value;
    if (val === '') {
      setDataSource(data);
    } else {
      const fData = data.filter((i) => i[searchAttr].search(val) !== -1);
      setDataSource(fData);
    }
  };

  return (
    <Modal
      title={title}
      visible={listModalVisible}
      footer={footer}
      onOk={onOk}
      onCancel={onCancel}
      zIndex={1200}
    >
      <Content>
        <Search
          onChange={handleChange}
          placeholder="搜索"
        />
      </Content>
      <div style={{ height: 550, overflow: 'auto' }}>
        <List
          bordered
          dataSource={dataSource}
          renderItem={renderItem}
        />
      </div>
    </Modal>
  );
};

ListModal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  searchAttr: PropTypes.string,
  listModalVisible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  renderItem: PropTypes.func,
  footer: PropTypes.arrayOf(PropTypes.any),
};

ListModal.defaultProps = {
  title: '',
  data: [],
  searchAttr: '',
  listModalVisible: false,
  onOk: () => {},
  onCancel: () => {},
  renderItem: () => {},
  footer: [],
};

export default ListModal;
