import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const DebitManageList = lazy(() => import('@/modules/financialManage/debitManage'));
const RepaymentManage = lazy(() => import('@/modules/financialManage/repaymentManage'));
const SupplierReconciliation = lazy(() => import('@/modules/financialManage/supplierReconciliation'));
export default {
  path: 'financialManage',
  element: <Outlet />,
  options: {
    title: '财务管理',
    permissionKey: 'menu-financialManage',
    key: 'menu-financialManage',
    icon: <Icon.ContactsOutlined />,
    link: '/financialManage',
    // allowAccess: true,
  },
  children: [
    {
      path: 'debitManage',
      element: <Outlet />,
      options: {
        title: '借支审核',
        key: 'menu-financialManage-debitManage',
        icon: null,
        link: '/financialManage/debitManage',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="借支审核" permissionKey="menu-financialManage-debitManage" component={<DebitManageList />} />,
        },
      ],
    }, {
      path: 'repaymentManage',
      element: <Outlet />,
      options: {
        title: '还款冲销',
        key: 'menu-financialManage-repaymentManage',
        icon: null,
        link: '/financialManage/repaymentManage',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="还款冲销" permissionKey="menu-financialManage-repaymentManage" component={<RepaymentManage />} />,
        },
      ],
    }, {
      path: 'supplierReconciliation',
      element: <Outlet />,
      options: {
        title: '供应商对账',
        key: 'menu-financialManage-supplierBill',
        icon: null,
        link: '/financialManage/supplierReconciliation',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="供应商对账" permissionKey="menu-financialManage-supplierBill" component={<SupplierReconciliation />} />,
        },
      ],
    },
  ],
};
