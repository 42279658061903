import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const MemberSalary = lazy(() => import('@modules/salarySlip/member'));
const InnerSalary = lazy(() => import('@modules/salarySlip/inner'));
export default {
  path: 'salarySlip',
  element: <Outlet />,
  options: {
    title: '掌上工资条',
    permissionKey: 'menu-salarySlip',
    key: 'menu-salarySlip',
    icon: <Icon.ContactsOutlined />,
    link: '/salarySlip',
  },
  children: [
    {
      path: 'memberSalary',
      element: <Outlet />,
      options: {
        title: '会员工资',
        key: 'menu-salarySlip-member',
        icon: null,
        link: '/salarySlip/memberSalary',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="会员工资" permissionKey="menu-salarySlip-member" component={<MemberSalary />} />,
        },
      ],
    },
    {
      path: 'innerSalary',
      element: <Outlet />,
      options: {
        title: '内部工资',
        key: 'menu-salarySlip-inner',
        icon: null,
        link: '/salarySlip/innerSalary',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="内部工资" permissionKey="menu-salarySlip-inner" component={<InnerSalary />} />,
        },
      ],
    },
  ],
};
