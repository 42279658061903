import { useEffect, useState } from 'react';
import httpRequest from '@utils/httpRequest';
import httpRequests from '@/utils/contractHttp';
import useOption from './useOption';
import useContractOptions from './useContractOptions';
import useAccountOptions from './useAccountOption';

// 用户下拉
function useAllUsers() {
  const [options, setOptions] = useState([]);
  const query = async () => {
    const res = await httpRequest.get('/admin/user/select/list');
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.label,
        value: c.value,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    query();
  }, []);
  return { options };
}

// 所有门店下拉框
function useAuthStores() {
  const [options, setOptions] = useState([]);
  const authStoresQuery = async () => {
    const res = await httpRequest.get('/admin/store/select/list');
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.label,
        value: c.value,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    authStoresQuery();
  }, []);
  return { options };
}

// 提成门店下拉框
function useEmpRewardStores(fromAll) {
  const [options, setOptions] = useState([]);
  const empRewardStoresQuery = async () => {
    const url = fromAll ? '/admin/empReward/store/empWard/forSelect' : '/admin/empReward/store/empWard/forSelect/exDisable';
    const res = await httpRequest.get(url);
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.label,
        value: c.value,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    empRewardStoresQuery();
  }, []);
  return { options };
}

function useAuthlist() {
  const { options } = useOption('/common/user/forSelect');
  return options;
}

// 未禁用楼栋数据
function useRoomBuilding() {
  const { options } = useOption('/common/roomBuilding/forSelect');
  return options;
}

// 全部楼栋数据
function useAllRoomBuilding() {
  const { options } = useOption('/common/roomBuilding/forSelect/all');
  return options;
}

// 所有企业
function useCompanies() {
  const { options } = useOption('/common/companies/forSelect');
  return options;
}

// 今日订单上架企业
function useRecruiCompany() {
  const { options } = useOption('/common/recruiting/company');
  return options;
}

// 集合点
function useMarshalPoint() {
  const { options } = useOption('/common/marshalPoint/forSelect');
  return options;
}

// 车辆
function useCarData() {
  const { options } = useOption('/common/car/forSelect');
  return options;
}

function useDepartments() {
  const { options } = useOption('/common/department/forSelect');
  return options;
}

function useRoles() {
  const { options } = useAccountOptions('/common/roles/forSelect');
  return options;
}

function useStores() {
  const { options } = useOption('/common/store/forSelect');
  return options;
}

function useGroup() {
  const { options } = useOption('/common/store/group/forSelect');
  return options;
}

function useSuppliers() {
  const { options } = useOption('/common/supplier/forSelect');
  return options;
}

// 合同类型
function useContractType() {
  const { options } = useContractOptions('/common/contractType/forSelect');
  return options;
}

// 认证企业
function useCompanyMains() {
  const { options } = useContractOptions('/common/companyMain/forSelect');
  return options;
}

// 获取认证企业主体
function useCompanyMain() {
  const [options, setOptions] = useState([]);
  const query = async () => {
    const res = await httpRequests.get('/common/companyMain/forSelect');
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.name,
        value: c.companyMainId,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    query();
  }, []);
  return { options };
}

// 获取合同模板
function useTemplate() {
  const { options } = useContractOptions('/common/contractTemp/forSelect');
  return options;
}

// 获取标签
function useTags() {
  const [options, setOptions] = useState([]);
  const query = async () => {
    const res = await httpRequest.get('/admin/member/tag');
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c,
        value: c,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    query();
  }, []);
  return { options };
}

// 订单数据联动
function useAllOrders() {
  const [options, setOptions] = useState([]);
  const query = async () => {
    const res = await httpRequest.get('/common/order/forSelect', {});
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.name,
        value: c.orderId,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    query();
  }, []);
  return { options };
}

// 查询上架的供应商订单
function useSupplierOrders() {
  const [options, setOptions] = useState([]);
  const query = async () => {
    const res = await httpRequest.post('/common/order/forSelect', { self: false });
    if (res.code === 0) {
      const list = res.data?.map((c) => ({
        label: c.name,
        value: c.orderId,
      }));
      setOptions(list);
    }
  };
  useEffect(() => {
    query();
  }, []);
  return { options };
}

// 获取文件夹
function useDocuments() {
  const { options } = useOption('/common/docuFolder/forSelect');
  return options;
}

export default {
  useAuthStores,
  useEmpRewardStores,
  useAllUsers,
  useAuthlist,
  useRoomBuilding,
  useAllRoomBuilding,
  useCompanies,
  useRecruiCompany,
  useMarshalPoint,
  useCarData,
  useDepartments,
  useStores,
  useGroup,
  useRoles,
  useContractType,
  useCompanyMains,
  useCompanyMain,
  useTemplate,
  useTags,
  useSuppliers,
  useAllOrders,
  useSupplierOrders,
  useDocuments,
};
