/*
 * @Author: TANG
 * @Date: 2022-06-20 16:44:27
 * @LastEditors: TANG 15390913806@163.com
 * @LastEditTime: 2022-07-02 09:08:59
 */
import React, { lazy } from 'react';
import { DefaultLayout } from '@layouts';

import appRoutes from './appRoutes';

const Login = lazy(() => import('../modules/login/login'));
const NotFound = lazy(() => import('@modules/error/notFound'));
const FillEdit = lazy(() => import('@modules/contractMannage/contractTemplate/FillEdit'));

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    children: appRoutes,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/contractTemp/:contractMainTempId/fill/edit',
    element: <FillEdit />,
  },
];
