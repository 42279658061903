import React from 'react';
import { useLocation, NavLink, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Layout } from 'antd';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
// import { useAliveController } from 'react-activation';
import KeepAlive, { withAliveScope, useAliveController } from 'react-activation';
import KeepAliveTabs from './KeepAliceTab';

const NavContent = () => {
  const { Header, Content, Footer } = Layout;
  const {
    drop, dropScope, clear, getCachingNodes,
  } = useAliveController();
  const cachingNodes = getCachingNodes();

  return (
    <Header style={{
      padding: 0, background: '#fff', height: 62, overflow: 'scroll',
    }}
    >
      <KeepAliveTabs />
    </Header>
  );
};

export default observer(NavContent);
