import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';

import authStore from '@stores/auth';
import { Header as MyHeader } from '@/components';
import { storageKeys } from '@/constants';
import { StyledHeader } from './styles';
import BreadCrumb from './breadcrumb';
import { httpRequest } from '@/utils';

const Header = () => {
  const [topData, setTopData] = useState(''); // 顶部提成数据
  const handleLogout = () => {
    authStore.clearlogInfo();
    Cookies.remove(storageKeys.token);
    Cookies.remove(storageKeys.permissions);
  };
  const getIndexTop = async () => {
    const res = await httpRequest.post('admin/index/top', {});
    if (res.code === 0) {
      setTopData(res.data);
    }
  };
  useEffect(() => {
    // if (authStore.profile.source === 'RECRUIT') {
    //   getIndexTop();
    // }
  }, []);
  return (
    <StyledHeader>
      <BreadCrumb />
      <MyHeader username={authStore.profile?.username} avatar={authStore.profile?.avatar} topData={topData} onLogout={handleLogout} />
    </StyledHeader>
  );
};

export default observer(Header);
