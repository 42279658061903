import styled from 'styled-components';
import { Form } from 'antd';

export const EmptyFormItem = styled(Form.Item)`
  > .ant-form-item-control {
    > .ant-form-item-explain {
      display: flex;
    }
  }
`;

export default {};
