import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';

const BusinessEntity = lazy(() => import('@/modules/contractMannage/businessEntity'));
const SealManage = lazy(() => import('@/modules/contractMannage/sealManage'));
const ContractConfig = lazy(() => import('@/modules/contractMannage/contractConfig'));
const ContractTemplate = lazy(() => import('@/modules/contractMannage/contractTemplate'));
const NewContract = lazy(() => import('@/modules/contractMannage/contractTemplate/newContract'));
const MemberContract = lazy(() => import('@/modules/contractMannage/memberContract'));
const FilingContracts = lazy(() => import('@/modules/contractMannage/filingContracts'));

export default {
  path: 'contractMannage',
  element: <Outlet />,
  options: {
    title: '合同管理',
    permissionKey: 'menu-contractMannage',
    key: 'menu-contractMannage',
    icon: <Icon.ContactsOutlined />,
    link: '/contractMannage',
    // allowAccess: true,
  },
  children: [
    {
      path: 'businessEntity',
      element: <Outlet />,
      options: {
        title: '企业主体',
        key: 'menu-contractMannage-businessEntity',
        icon: null,
        link: '/contractMannage/businessEntity',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="企业主体" permissionKey="menu-contractMannage-businessEntity" component={<BusinessEntity />} />,
        },
      ],
    },
    {
      path: 'sealManage',
      element: <Outlet />,
      options: {
        title: '印章管理',
        key: 'menu-contractMannage-sealManage',
        icon: null,
        link: '/contractMannage/sealManage',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="印章管理" permissionKey="menu-contractMannage-sealManage" component={<SealManage />} />,
        },
      ],
    },
    {
      path: 'contractConfig',
      element: <Outlet />,
      options: {
        title: '合同配置',
        key: 'menu-contractMannage-contractConfig',
        icon: null,
        link: '/contractMannage/contractConfig',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="合同配置" permissionKey="menu-contractMannage-contractConfig" component={<ContractConfig />} />,
        },
      ],
    },
    {
      path: 'contractTemplate',
      element: <Outlet />,
      options: {
        title: '合同模板',
        key: 'menu-contractMannage-contractTemplate',
        icon: null,
        link: '/contractMannage/contractTemplate',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="合同模板" permissionKey="menu-contractMannage-contractTemplate" component={<ContractTemplate />} />,
        },
        {
          path: ':contractId/edit',
          element: <ProtectedRoute title="编辑合同模板" permissionKey="menu-contractMannage-contractTemplate" component={<NewContract />} />,
        },
      ],
    },
    {
      path: 'memberContract',
      element: <Outlet />,
      options: {
        title: '会员合同',
        key: 'menu-contractMannage-memberContract',
        icon: null,
        link: '/contractMannage/memberContract',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="会员合同" permissionKey="menu-contractMannage-memberContract" component={<MemberContract />} />,
        },
      ],
    },
    {
      path: 'filingContracts',
      element: <Outlet />,
      options: {
        title: '归档合同',
        key: 'menu-contractMannage-filingContracts',
        icon: null,
        link: '/contractMannage/filingContracts',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="归档合同" permissionKey="menu-contractMannage-filingContracts" component={<FilingContracts />} />,
        },
      ],
    },
  ],
};
